// React
import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { refresh, addCircle, search, checkmarkOutline, arrowRedoOutline, ticketOutline, alertCircleOutline } from 'ionicons/icons'

// Layout
import { Layout } from "../../layout/Layout";

// Context
import { AppContext } from "../../layout/AppContext";
import { FilterBussiness } from "./Filters/FilterBussiness";
import { FilterStatus } from "./Filters/FilterStatus";
import { FilterPriority } from "./Filters/FilterPriority";

import { AssignedToMe } from "./Buttons/AssignedToMe";
import { CreatedByMe } from "./Buttons/CreatedByMe";
import { AllCanISeeTickets } from "./Buttons/AllCanISeeTickets";
import { NoOwner } from "./Buttons/NoOwner";
import { ImManager } from "./Buttons/ImManager";
import { ButtonListForMobile } from "./ButtonListForMobile";

import './style.css';

import Countdown from "react-countdown";

function HelpDeskPage() {
    moment.locale('es-mx')
    let navigate = useNavigate();
    // Context
    const { loading, setLoading, guestRedirect, API_ADDRESS, getToken, countdownRenderer} = React.useContext(AppContext);

    const [tickets, setTickets] = React.useState([]);
    const [listWhat, setListWhat] = React.useState('noowner')

    async function getTickets(){
        setLoading(true)
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/',
            headers: { Authorization: 'Token ' + getToken()}
        })
        .then(function (response) {
            setTickets(response.data);
        })
        .catch(function (err) {
            setLoading(false);
        });
    }

    function momentDate(mydate) {
        const poronga = moment.utc(mydate).local().startOf('seconds').fromNow();
        return poronga;
    }

    const getListWhat = () => {
        const listWhatItem = localStorage.getItem('listWhat');
        if (!listWhatItem){
            localStorage.setItem('listWhat', 'noowner')
            document.getElementById('noowner').click();
            return ('');
        } else {
            setListWhat(listWhatItem)
            document.getElementById(listWhatItem).click();
            return ('');
        }
    }

    function saveListWhat(text){
        localStorage.setItem('listWhat', text);
        setListWhat(text);
    }

    async function getMyProfile() {
        try {
            let response = await axios({
                method: 'get',
                url: API_ADDRESS + 'profiles/',
                headers: { Authorization: 'Token ' + getToken()}
            });
            if (response.status === 404) {
                navigate('/404');
            }
            if (response.status === 200) {
                if (response.data.client_profile) {
                    navigate('/404');
                } else {
                    
                }
            }
        }
        catch (e) {}
    }

    React.useEffect( () => {
        guestRedirect();
        getMyProfile();
        getListWhat();
    }, [])

    return (
        <Layout noContainer={true}>
            {/* <div className="box">
                <div className="field has-addons">
                <p className="control">
                
                </p>
                <p className="control">
                <button className="button is-info">Tickets abiertos</button>
                </p>
                <p className="control">
                <button className="button is-primary">Asignados a mí</button>
                </p>
                <p className="control">
                <button className="button is-info">Asignados a otros</button>
                </p>
                <p className="control">
                <button className="button">Sin asignar</button>
                </p>
                <p className="control">
                <button className="button is-warning">Por vencer 1</button>
                </p>
                <p className="control">
                <button className="button is-danger">Atrasados</button>
                </p>
                </div>
            </div> */}
            <div className="box" style={{borderRadius: '0', border: '1px solid #eeeeee', marginBottom: '-1px', padding: '16px 8px 16px 20px'}}>
                <div className="field has-addons">
                    <Link className="button" to={'/tickets/new'} style={{marginRight:'8px', backgroundColor: '#ff7143', color: '#FFF', border: 0}}>
                        <IonIcon icon={addCircle} />
                        <div style={{transform: 'translateY(2px)', marginLeft: '8px', fontWeight: 'bold'}}>Nuevo Ticket</div>
                    </Link>

                    <Link className="button" to={'/tickets/finder'} style={{marginRight:'8px'}}>
                        <IonIcon icon={search} />
                        <div style={{transform: 'translateY(2px)', marginLeft: '8px', fontWeight: 'bold'}}>Buscador</div>
                    </Link>
                    
                    {/* <FilterBussiness />
                    <FilterStatus />
                    <FilterPriority />

                    <p className="control">
                        <Link className="button is-warning" to={'/tickets/new'} style={{marginRight: '8px', borderRadius:'4px'}}>
                            <IonIcon icon={refresh} />
                        </Link>
                    </p>
                    <p className="control">
                        <Link className="button is-link is-ok" to={'/tickets/new'} style={{borderRadius: '4px'}}>
                            <IonIcon icon={search} />
                        </Link>
                    </p> */}
                </div>
            </div>

            <div className="box" style={{borderRadius: '0', marginBottom: '-1px', border: '1px solid #eeeeee', padding: '16px 8px 16px 20px'}}>
                <ButtonListForMobile 
                    setTickets={setTickets}
                    saveListWhat={saveListWhat}
                />

                <div className="field has-addons" id="ticket-buttons">
                    <div className="control" style={{marginRight: '16px', borderRight: '2px solid #444', paddingRight: '16px'}}>
                        <NoOwner
                            setTickets={setTickets}
                            saveListWhat={saveListWhat}
                        />
                    </div>
                    <div className="control" style={{marginRight: '16px', borderRight: '2px solid #444', paddingRight: '16px'}}>
                        <ImManager
                            setTickets={setTickets}
                            saveListWhat={saveListWhat}
                        />
                    </div>
                    <div className="control" style={{marginRight: '16px', borderRight: '2px solid #444', paddingRight: '16px'}}>
                        <AssignedToMe
                            setTickets={setTickets}
                            saveListWhat={saveListWhat}
                        />
                    </div>
                    <div className="control" style={{marginRight: '16px', borderRight: '2px solid #444', paddingRight: '16px'}}>
                        <CreatedByMe
                            setTickets={setTickets}
                            saveListWhat={saveListWhat}
                        />
                    </div>
                    <div className="control" style={{marginRight: '16px'}}>
                        <AllCanISeeTickets 
                            setTickets={setTickets}
                            saveListWhat={saveListWhat}
                        />
                    </div>
                </div>
            </div>

            <div className="box" style={{borderRadius: '0', border: '1px solid #eeeeee'}}>
                <div>
                        {listWhat === 'noowner' && <div style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '12px'}}>
                            <div style={{float: 'left', marginRight: '8px', transform: 'translateY(3px)'}}>
                                <IonIcon icon={alertCircleOutline} />
                            </div>
                            Sin Asignar ({tickets.length})
                        </div>}
                        {listWhat === 'im_manager' && 
                        <div style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '12px'}}>
                            <div style={{float: 'left', marginRight: '8px', transform: 'translateY(3px)'}}>
                                <IonIcon icon={checkmarkOutline} />
                            </div>
                            Mis Responsabilidades ({tickets.length})
                        </div>}
                    {listWhat === 'assigned_to_me' && 
                        <div style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '12px'}}>
                            <div style={{float: 'left', marginRight: '8px', transform: 'translateY(3px)'}}>
                                <IonIcon icon={checkmarkOutline} />
                            </div>
                            Asignados a mi ({tickets.length})
                        </div>}
                    {listWhat === 'created_by_me' && 
                        <div style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '12px'}}>
                            <div style={{float: 'left', marginRight: '8px', transform: 'translateY(3px)'}}>
                                <IonIcon icon={arrowRedoOutline} />
                            </div>
                            Creados por mi ({tickets.length})
                        </div>}
                    {listWhat === 'all_i_can_see' && <div style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '12px'}}>
                            <div style={{float: 'left', marginRight: '8px', transform: 'translateY(3px)'}}>
                                <IonIcon icon={ticketOutline} />
                            </div>
                            Todos ({tickets.length})
                        </div>}
                </div>
                <div className="table-container">
                    <table className="table is-bordered is-narrow is-hoverable is-fullwidth" style={{fontSize: '12px'}}>
                        <thead style={{backgroundColor: '#f5f5f5'}}>
                            <tr>
                                <th style={{width: '24px', textAlign: 'center'}}>P</th>
                                {/* <th><abbr title="ID">ID</abbr></th> */}
                                <th style={{width: '158px'}}>Categoria</th>
                                <th style={{width: '64px'}}><abbr title="Código">Código</abbr></th>
                                <th>Asunto</th>
                                <th style={{width: '180px'}}>Publicado por</th>
                                <th style={{width: '180px'}}>Empresa</th>
                                <th style={{width: '128px'}}>Estado</th>
                                <th style={{width: '180px'}}>Asignado a</th>
                                {/* <th>Responsable</th> */}
                                {/* <th>Última Respuesta</th> */}
                                <th style={{width: '98px', textAlign: 'center'}}>Creado</th>
                                <th style={{width: '98px', textAlign: 'center'}}>Vencimiento</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(tickets) && tickets.map(ticket =>
                                <tr key={ticket.id}>
                                    <td style={{textAlign: 'center'}}>
                                        {ticket.priority === 1 && <div className="priority-icon low"></div>}
                                        {ticket.priority === 2 && <div className="priority-icon mid"></div>}
                                        {ticket.priority === 3 && <div className="priority-icon high"></div>}
                                    </td>
                                    <td style={{fontWeight: 'bold'}}>
                                        {ticket.is_incident ?
                                        <div style={{color:'#ff3860'}}>{ticket.category.name}</div>:
                                        <div style={{color:'#48c774'}}>{ticket.category.name}</div>}
                                    </td>
                                    {/* <th><abbr title="ID">{ticket.id}</abbr></th> */}
                                    <td style={{fontWeight: 'bold'}}><Link to={'/tickets/' + ticket.id}>{ticket.code}</Link></td>
                                    <td>
                                        {!ticket.first_answer_maked &&
                                            // <div className="priority-icon first_answer_waiting"></div>
                                            <Countdown
                                                date={ticket.first_answer_expiration}
                                                renderer={countdownRenderer}
                                            ></Countdown>
                                        }
                                        {ticket.subject}
                                    </td>
                                    <td>{ticket.sender.name + ' ' + ticket.sender.lastname}</td>
                                    <td>{ticket.enterprise ? ticket.enterprise.name : ''}</td>
                                    {/* <td>{ticket.enterprise}</td> */}
                                    <td>
                                        {ticket.ticket_status && <div className="priority-mark" style={{backgroundColor: ticket.ticket_status.background_color, color: ticket.ticket_status.font_color }}>{ticket.ticket_status.private_name}</div>}
                                    </td>
                                    <td>{ticket.owner ? ticket.owner.name + ' ' + ticket.owner.lastname : ' - '}</td>
                                    {/* <td>{ticket.ticket_manager ? ticket.ticket_manager.name + ' ' + ticket.ticket_manager.lastname : ' - '}</td> */}
                                    {/* <td>{ticket.last_answer && ticket.last_answer.name}</td> */}
                                    <td style={{textAlign: 'center'}}>{momentDate(ticket.created_at)}</td>
                                    <td style={{textAlign: 'center'}}>
                                        <Countdown
                                            date={ticket.expiration}
                                            renderer={countdownRenderer}
                                        ></Countdown>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        
                    </table>
                </div>
            </div>
        </Layout>
    )
}

export { HelpDeskPage };