// React
import React from "react";
import { IonIcon } from "@ionic/react";
import { send, person, keySharp } from 'ionicons/icons'

// Context
import { AppContext } from "../../layout/AppContext";

import { LoginFunctions } from "./LoginFunctions";

function LoginPage(props) {

    // App Context
    const {
        loading,
        error,
        setError,
        } = React.useContext(AppContext);

    // Login Functions
    const {
        submitLogin,
        setLoginUsername,
        setLoginPassword,
        nextPage,
        setNextPage,
    } = LoginFunctions();

    

    React.useEffect( () => {
        const urlparams = new URL(window.location);
        if (urlparams.searchParams.get('next') === "") {
            setNextPage('/')
        } else {
            setNextPage(urlparams.searchParams.get('next'));
        }
    }, [])

    return (
        <React.Fragment>
            <div className="login-page-left">
                <div className="login-logo"></div>
                <div 
                    className="box" 
                    style={{
                        width: "70%", 
                        top: "50%", 
                        left: "50%", 
                        position: "absolute", 
                        transform: "translate(-50%, -50%)", backgroundColor: 'transparent'}}
                >
                <span className="login-text">Bienvenidos al portal de soporte técnico de Mesa de Servicios IT</span>
                <br /><br />
                <form onSubmit={submitLogin}>
                    <span className="title is-5" style={{fontFamily: 'MadeTommy-SoftBold'}}>Iniciar Sesión</span>
                    <div className="control has-icons-left has-icons-right" style={{marginTop: '16px'}}>
                        <input 
                            className="input" 
                            type="text" 
                            placeholder="Usuario"
                            name="user"
                            onChange={
                                e=> setLoginUsername(e.target.value)
                            }
                        />
                        <span className="icon is-small is-left">
                            <IonIcon icon={person} />
                        </span>
                        <span className="icon is-small is-right">
                            <i className="fas fa-check"></i>
                        </span>
                    </div>
                    <div 
                        className="control has-icons-left has-icons-right" 
                        style={{marginTop: "8px"}}
                    >
                        <input 
                            className="input" 
                            type="password" 
                            placeholder="Contraseña"
                            name="user"
                            onChange={
                                e=> setLoginPassword(e.target.value)
                            }
                        />
                        <span className="icon is-small is-left">
                            <IonIcon icon={keySharp} />
                        </span>
                        <span className="icon is-small is-right">
                            <i className="fas fa-check"></i>
                        </span>
                    </div>
                    <button 
                        className={
                            loading ? 
                            "button is-info is-small is-pulled-right is-loading" :
                            "button is-info is-small is-pulled-right"
                            } 
                        type="submit" 
                        style={{marginTop: '16px'}}
                    >
                        <span className="icon is-small">
                            <IonIcon icon={send} />
                        </span>
                        <span>Iniciar sesión</span>
                    </button>
                </form>
                {error &&
                <div className="has-text-danger" style={{width: "100%", marginTop: "50px"}}>
                    El usuario y/o la contraseña son incorrectos.
                </div>
                }                
                </div>

                <div className="login-links">
                    <a href="https://mesadeserviciosit.com" target="_blank">
                        <div className="www"></div>
                    </a>
                    <a href="https://st.mesadeserviciosit.com" target="_blank">
                        <div className="app"></div>
                    </a>
                    <a href="https://www.instagram.com/ms.i.t/" target="_blank">
                        <div className="instagram"></div>
                    </a>
                </div>
            </div>

            <div className="login-page-right">
                <div className="login-photo"></div>
            </div>
        </React.Fragment>
    )
}

export { LoginPage };