// React
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../layout/AppContext";

import { IonIcon } from "@ionic/react";
import { checkmark } from "ionicons/icons";

import Countdown from "react-countdown";

function TicketOptions(props){

    // Context
    const { loading, setLoading, guestRedirect, API_ADDRESS, getToken, MEDIA_ADDRESS, countdownRenderer, notify } = React.useContext(AppContext);

    const [closeTicket, setCloseTicket] = React.useState(false);

    const navigate = useNavigate();

    const [solvedComment, setSolvedComment] = React.useState('');

    async function putTicketMarkSolved(){
        setLoading(true)
        await axios({
            method: 'put',
            url: API_ADDRESS + 'tickets/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                close_ticket: true
            },
            data: {
                id: props.ticket.id,
                solved_comment: solvedComment,
            }
        })
        .then(function (response) {
            notify('Ticket cerrado', 'success')
            navigate('/tickets')
        })
        .catch(function (err) {
            notify('Algo no funcionó', 'error')
            setLoading(false);
        });
    }

    const [ticketStatusLoading, setTicketStatusLoading] = React.useState(false)

    async function putTicketStatus(newStatus){
        setTicketStatusLoading(true)
        await axios({
            method: 'put',
            url: API_ADDRESS + 'tickets/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                change_status: true
            },
            data: {
                id: props.ticket.id,
                status: newStatus
            }
        })
        .then(function (response) {
            setTicketStatusLoading(false);
            props.getTicket();
        })
        .catch(function (err) {
            setTicketStatusLoading(false);
        });
    }

    const [ticketPriorityLoading, setTicketPriorityLoading] = React.useState(false)

    async function putTicketPriority(newPriority){
        setTicketPriorityLoading(true);
        await axios({
            method: 'put',
            url: API_ADDRESS + 'tickets/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                change_priority: true
            },
            data: {
                id: props.ticket.id,
                priority: newPriority
            }
        })
        .then(function (response) {
            setTicketPriorityLoading(false);
            props.getTicket();
        })
        .catch(function (err) {
            setTicketPriorityLoading(false);
        });
    }

    const [categories, setCategories] = React.useState([]);
    async function getCategories(id_ticket){
        setLoading(true)
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/category/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                ticket: id_ticket
            }
        })
        .then(function (response) {
            setCategories(response.data);
        })
        .catch(function (err) {
            setLoading(false);
        });
    }

    const [statusList, setStatusList] = React.useState([]);

    async function getStatusList(){
        setTicketStatusLoading(true);
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/status/',
            headers: { Authorization: 'Token ' + getToken()},
        })
        .then(function (response) {
            setStatusList(response.data);
            setTicketStatusLoading(false);
        })
        .catch(function (err) {
            setTicketStatusLoading(false);
        });
    }

    const [technisians, setTechnisians] = React.useState([]);
    const [technisiansLoading, setTechnisiansLoading] = React.useState(false)

    async function getTechnisians(){
        setTechnisiansLoading(true)
        await axios({
            method: 'get',
            url: API_ADDRESS + 'profiles/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                get_technisian: true,
            }
        })
        .then(function (response) {
            setTechnisians(response.data);
            setTechnisiansLoading(false);
        })
        .catch(function (err) {
            setTechnisiansLoading(false);
        });
    }

    const [ticketOwnerLoading, setTicketOwnerLoading] = React.useState(false)

    async function putTicketOwner(newOwner){
        setTicketOwnerLoading(true);
        await axios({
            method: 'put',
            url: API_ADDRESS + 'tickets/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                change_owner: true
            },
            data: {
                id: props.ticket.id,
                owner: newOwner
            }
        })
        .then(function (response) {
            setTicketOwnerLoading(false);
            props.getTicket();
        })
        .catch(function (err) {
            setTicketOwnerLoading(false);
        });
    }

    const [ticketCategoryLoading, setTicketCategoryLoading] = React.useState(false)

    async function putTicketCategory(newCategory){
        setTicketCategoryLoading(true);
        await axios({
            method: 'put',
            url: API_ADDRESS + 'tickets/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                change_category: true
            },
            data: {
                id: props.ticket.id,
                category: newCategory
            }
        })
        .then(function (response) {
            setTicketCategoryLoading(false);
            props.getTicket();
        })
        .catch(function (err) {
            setTicketCategoryLoading(false);
        });
    }

    const [ticketManagerLoading, setTicketManagerLoading] = React.useState(false)

    async function putTicketManager(newManager){
        setTicketManagerLoading(true);
        await axios({
            method: 'put',
            url: API_ADDRESS + 'tickets/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                change_ticket_manager: true
            },
            data: {
                id: props.ticket.id,
                ticket_manager: newManager
            }
        })
        .then(function (response) {
            setTicketManagerLoading(false);
            props.getTicket();
        })
        .catch(function (err) {
            setTicketManagerLoading(false);
        });
    }

    React.useEffect( () => {
        if (props.ticket) {
            getCategories(props.ticket.id);
        }
        getTechnisians();
        getStatusList();
    }, [props.ticket])

    return(
        <div
        className={"box " + props.forMobile && props.forMobile === true ? 'is-hidden-tablet' : 'is-hidden-mobile'}
        style={{
            border: '2px solid #5a6672',
            borderRadius: '8px',
            marginBottom: '8px',
            padding: '12px 12px 5px 12px'
        }}
        >
            <h6 className="title is-6 has-text-centered">Opciones del ticket</h6>
            <div className="columns is-mobile">
                <div className="column is-6">
                    Empresa
                </div>
                <div className="column is-6">
                    <div className="avatar-mini-enterprise">
                        {props.ticket && props.ticket.enterprise && props.ticket.enterprise.name &&
                            (props.ticket.enterprise.avatar  ?
                            <img src={MEDIA_ADDRESS + props.ticket.enterprise.avatar}/>
                        :<span>{props.ticket.enterprise.name.slice(0,2)}</span>
                        )}
                    </div>
                    <span style={{textDecoration: 'underline'}}>{props.ticket && props.ticket.enterprise && props.ticket.enterprise.name}</span>
                </div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-6">
                    Tipo
                </div>
                <div className="column is-6">
                <strong>
                {props.ticket.is_incident ?
                    <React.Fragment>
                    <div 
                        style={{
                            width:'16px',
                            height: '16px', 
                            backgroundColor:'#ff3860', 
                            borderRadius: '8px', 
                            boxShadow: '0 0 2px #212121', 
                            display: 'inline-block', 
                            transform: 'translateY(2px)'
                        }} />
                        &nbsp;Incidente
                    </React.Fragment>
                    :
                    <React.Fragment>
                    <div 
                        style={{
                            width:'16px',
                            height: '16px', 
                            backgroundColor:'#48c774', 
                            borderRadius: '8px', 
                            boxShadow: '0 0 2px #212121', 
                            display: 'inline-block', 
                            transform: 'translateY(2px)'
                        }}/>
                        &nbsp;Req.
                    </React.Fragment>
                    }
                    
                </strong>
                </div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-6">
                    Primera Respuesta
                </div>
                <div className="column is-6">
                    {props.ticket.open ?
                        (props.ticket.first_answer_maked ?
                            (
                                props.ticket.first_answer_intime ?
                                <React.Fragment>
                                    <div className="expiration-answer-icon intime" />
                                </React.Fragment> :
                                <React.Fragment>
                                    <div className="expiration-answer-icon not-intime" />
                                </React.Fragment>
                                
                            ): 
                            (props.ticket.expiration &&
                                <Countdown
                                    date={props.ticket.first_answer_expiration}
                                    renderer={countdownRenderer}
                                />
                            )
                        ) :
                        (
                            props.ticket.first_answer_intime ?
                            <React.Fragment>
                                <div className="expiration-answer-icon intime" />
                            </React.Fragment> :
                            <React.Fragment>
                                <div className="expiration-answer-icon not-intime" />
                            </React.Fragment>
                            
                        )
                    }
                </div>
            </div>    
            <div className="columns is-mobile">
                <div className="column is-6">
                    Vencimiento
                </div>
                <div className="column is-6">
                    {props.ticket.open ?
                        (
                            props.ticket.expiration &&
                            <Countdown
                                date={props.ticket.expiration}
                                renderer={countdownRenderer}
                            />
                        ) :
                        (
                            props.ticket.solved_intime ?
                            <React.Fragment>
                                <div className="expiration-answer-icon intime" />
                            </React.Fragment> :
                            <React.Fragment>
                                <div className="expiration-answer-icon not-intime" />
                            </React.Fragment>
                            
                        )
                    }
                </div>
            </div>    
            <div className="columns is-mobile">
                <div className="column is-6">
                    Estado
                </div>
                <div className="column is-6">
                    <div className={"select is-fullwidth is-small " + (ticketStatusLoading && "is-loading")}>
                        <select onChange={e => {putTicketStatus(e.target.value)}} value={props.ticket && props.ticket.ticket_status && props.ticket.ticket_status.id} disabled={props.ticket.open ? false : true}>
                            <option value='0'>--</option>
                            {statusList && statusList.map(status =>
                                (props.ticket.open ? (status.close_status === false && 
                                    <option key={status.id} value={status.id}>{status.private_name}</option>
                                ) : <option key={status.id} value={status.id}>{status.private_name}</option> )
                            )}                            
                        </select>
                    </div>
                </div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-6">
                    Categoria
                </div>
                <div className="column is-6">
                    <div className={"select is-fullwidth is-small " + (ticketCategoryLoading && "is-loading")}>
                        <select disabled={props.ticket.open ? false : true} onChange={e => {putTicketCategory(e.target.value)}} value={props.ticket.category && props.ticket.category.id}>
                            <option value="0">--</option>
                            {categories && categories.map(cat =>
                                <option key={cat.id} value={cat.id}>{cat.name}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-6">
                    Prioridad
                </div>
                <div className="column is-6">
                    <div className={
                        "select is-fullwidth is-small " + 
                        (props.ticket.priority === 1 ? 'is-success' : 
                        props.ticket.priority === 2 ? 'is-warning' : 'is-danger')
                        + (ticketPriorityLoading ? ' is-loading' : '')
                    }
                    >
                        <select onChange={e => {putTicketPriority(e.target.value)}} value={props.ticket.priority}  disabled={props.ticket.open ? false : true}>
                            <option value='1'>Baja</option>
                            <option value='2'>Media</option>
                            <option value='3'>Alta</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-6">
                    Asignado a
                </div>
                <div className="column is-6">
                    <div className={"select is-fullwidth is-small" + (ticketOwnerLoading ? ' is-loading' : '')}>
                        <select onChange={e => {putTicketOwner(e.target.value)}} value={props.ticket.owner ? props.ticket.owner.id : '0'}  disabled={props.ticket.open ? false : true}>
                            <option value="0">--</option>
                            {technisians && technisians.map(tech =>
                                <option key={tech.id} value={tech.id}>{tech.name + ' ' + tech.lastname}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-6">
                    Responsable
                </div>
                <div className="column is-6">
                    <div className={"select is-fullwidth is-small is-disabled" + (ticketManagerLoading ? ' is-loading' : '')}>
                        <select onChange={e => {putTicketManager(e.target.value)}} value={props.ticket.ticket_manager ? props.ticket.ticket_manager.id : '0'} disabled={true}>
                            <option value="0">--</option>
                            {technisians.map(tech =>
                                <option key={tech.id} value={tech.id}>{tech.name + ' ' + tech.lastname}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
            {props.ticket.open &&
                <React.Fragment>
                    {!closeTicket &&
                    <button 
                        className="button is-success is-outlined is-small is-fullwidth"
                        onClick={() => setCloseTicket(!closeTicket)}
                    >
                        <span className="icon is-small">
                            <IonIcon icon={checkmark} />
                        </span>
                        <span>Cerrar ticket</span>
                    </button>
                    }
                    {closeTicket &&
                        <React.Fragment>
                            <hr />
                            <button 
                                className="button is-outlined is-small is-fullwidth"
                                onClick={() => setCloseTicket(!closeTicket)}
                            >
                                <span className="icon is-small">
                                    <IonIcon icon={checkmark} />        
                                </span>
                                <span>Cancelar</span>
                            </button>
                            Comentario final
                            <br />
                            <textarea onChange={e => {setSolvedComment(e.target.value)}}
                                    className="textarea" 
                                    placeholder="Escribe el mensaje aquí"
                                    // onChange={e => {setMessageInput(e.target.value)}}
                                    id="new_message_input"
                                    style={{marginBottom: '4px', marginTop: '2px'}}
                                >
                                </textarea>
                            <div className="columns is-mobile">
                                <div className="column is-6">
                                    Estado cierre
                                </div>
                                <div className="column is-6">
                                    <div className={"select is-fullwidth is-small " + (ticketStatusLoading && "is-loading")}>
                                        <select onChange={e => {putTicketStatus(e.target.value)}} value={props.ticket && props.ticket.ticket_status && props.ticket.ticket_status.id} disabled={props.ticket.open ? false : true}>
                                            <option value='0'>--</option>
                                            {statusList && statusList.map(status =>
                                                (status.close_status === true && 
                                                    <option key={status.id} value={status.id}>{status.private_name}</option>
                                                )
                                            )}                            
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button className="button is-success is-outlined is-small is-fullwidth" onClick={() => putTicketMarkSolved()}>
                                <span className="icon is-small">
                                    <IonIcon icon={checkmark} />             
                                </span>
                                <span>Cerrar ticket</span>
                            </button>
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </div>         
    )
}

export { TicketOptions };