import React from "react";
import "./style.css"

import { IonIcon } from "@ionic/react";
import { alertCircleOutline } from 'ionicons/icons'

function ButtonListForMobile(props){
    return (
        <React.Fragment>
            <div className="ticket-button-list">
                <div className="btn-select">
                    <IonIcon icon={alertCircleOutline} style={{transform: 'translateY(2px)'}}/> <span> &nbsp; Sin Asignar</span>
                </div>
                <div className="btn-select-arrow">   
                </div>
            </div>
        </React.Fragment>
    )
}

export { ButtonListForMobile };