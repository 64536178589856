// React
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";

import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { refresh, addCircle, search, checkmarkOutline, arrowRedoOutline, ticketOutline, alertCircleOutline } from 'ionicons/icons'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Layout
import { Layout } from "../../../layout/Layout";

// Context
import { AppContext } from "../../../layout/AppContext";



import './style.css'

import { Pagination } from "./Pagination";

function TicketFinderPage() {
    moment.locale('es-mx')
    let navigate = useNavigate();
    // Context
    const { loading, setLoading, guestRedirect, API_ADDRESS, getToken } = React.useContext(AppContext);

    const [tickets, setTickets] = React.useState([]);
    const [listWhat, setListWhat] = React.useState('noowner')

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [filterType, setFilterType] = React.useState(null);
    const [filterEnterprise, setFilterEnterprise] = React.useState(null);
    const [filterStatus, setFilterStatus] = React.useState(null);
    const [filterPriority, setFilterPriority] = React.useState(null);
    const [filterClosed, setFilterClosed] = React.useState(null);

    const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <input className="input is-small" type="text" value={value} onClick={onClick} ref={ref} readOnly/>
    ));
    
    const [registersCount, setRegisterCount] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0)
    const [currentPage, setCurrentPage] = React.useState(0)

    const page_size = 20;

    async function getTickets(page){
        if (page === null) {
            setPageCount(0);
        }
        setLoading(true)
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/finder/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                date_start: startDate,
                date_end: endDate,
                enterprise: filterEnterprise,
                type: filterType,
                status: filterStatus,
                priority: filterPriority,
                closed: filterClosed,
                page: page,
            }

        })
        .then(function (response) {
            if (page === null) {
                let cantidad_registros = response.data.count;
                let cantidad_paginas = cantidad_registros / page_size;
                setPageCount(parseInt(cantidad_paginas + 1))
                setCurrentPage(1);
            } else {
                setCurrentPage(page);
            }
            setTickets(response.data.results);
            setRegisterCount(response.data.count);
        })
        .catch(function (err) {
            setLoading(false);
        });
    }

    function momentDate(mydate) {
        const poronga = moment.utc(mydate).local().startOf('seconds').fromNow();
        return poronga;
    }

    const [bussinessPermision, setBussinessPermission] = React.useState();

    async function getMyEnterprises() {
        setLoading(true)
        await axios({
            method: 'get',
            url: API_ADDRESS + 'profiles/bussiness_permission/',
            headers: { Authorization: 'Token ' + getToken()}
        })
        .then(function (response){
            setLoading(false);
            setBussinessPermission(response.data);
        })
        .catch(function (err) {
            setLoading(false);
        })
    }

    async function getMyProfile() {
        try {
            let response = await axios({
                method: 'get',
                url: API_ADDRESS + 'profiles/',
                headers: { Authorization: 'Token ' + getToken()}
            });
            if (response.status === 404) {
                navigate('/404');
            }
            if (response.status === 200) {
                if (response.data.client_profile) {
                    navigate('/404');
                } else {
                    
                }
            }
        }
        catch (e) {}
    }
    
    React.useEffect( () => {
        guestRedirect();
        getMyProfile();
        getMyEnterprises();
    }, [])

    return (
        <Layout noContainer={true}>
            <div className="box" style={{borderRadius: '0', border: '1px solid #eeeeee', marginBottom: '-1px', padding: '16px 8px 16px 20px'}}>

                <div className="field is-grouped is-grouped-multiline">
                    <p className="control">
                    <div className="field is-horizontal">
                        <div className="field-label is-small">
                            <label className="label">Empresa</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <div className="select is-small">
                                        <select onChange={e => setFilterEnterprise(e.target.value)}>
                                            <option value={0} onClick={() => setFilterEnterprise(null)}>-</option>
                                            {bussinessPermision && bussinessPermision.enterprise.map(enterprise =>
                                                <option 
                                                    key={enterprise.id}
                                                    value={enterprise.id}  
                                                    onClick={() => setFilterEnterprise(enterprise.id)}
                                                >
                                                    {enterprise.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                    </p>

                    <p className="control">
                    <div className="field is-horizontal">
                        <div className="field-label is-small">
                            <label className="label">Tipo</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <div className="select is-small">
                                        <select onChange={e => setFilterType(e.target.value)}>
                                            <option value={2} onClick={() => setFilterType(null)}>-</option>
                                            <option value={0} onClick={() => setFilterType(0)}>Requerimiento</option>
                                            <option value={1} onClick={() => setFilterType(1)}>Incidente</option>
                                        </select>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                    </p>

                    <p className="control">
                    <div className="field is-horizontal">
                        <div className="field-label is-small">
                            <label className="label">Estado</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <div className="select is-small">
                                        <select onChange={e => setFilterStatus(e.target.value)}>
                                            <option value={0} onClick={() => setFilterStatus(null)}>-</option>
                                            <option value={1} onClick={() => setFilterStatus(1)}>Pendiente</option>
                                            <option value={2} onClick={() => setFilterStatus(2)}>En Progreso</option>
                                            <option value={3} onClick={() => setFilterStatus(3)}>Respondido</option>
                                            <option value={4} onClick={() => setFilterStatus(4)}>En Espera</option>
                                            <option value={5} onClick={() => setFilterStatus(5)}>Resuelto</option>
                                            <option value={6} onClick={() => setFilterStatus(6)}>Cerrado Resuelto</option>
                                            <option value={7} onClick={() => setFilterStatus(7)}>Cerrado Sin Resolver</option>
                                        </select>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                    </p>

                    <p className="control">
                    <div className="field is-horizontal">
                        <div className="field-label is-small">
                            <label className="label">Prioridad</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <div className="select is-small">
                                        <select onChange={e => setFilterPriority(e.target.value)}>
                                            <option value={0} onClick={() => setFilterPriority(null)}>-</option>
                                            <option value={1} onClick={() => setFilterPriority(1)}>Baja</option>
                                            <option value={2} onClick={() => setFilterPriority(2)}>Media</option>
                                            <option value={3} onClick={() => setFilterPriority(3)}>Alta</option>
                                        </select>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                    </p>

                    <p className="control">
                    <div className="field is-horizontal">
                        <div className="field-label is-small">
                            <label className="label">Cerrados</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <div className="select is-small">
                                        <select onChange={e => setFilterClosed(e.target.value)}>
                                            <option value={2} onClick={() => setFilterClosed(null)}>-</option>
                                            <option value={1} onClick={() => setFilterClosed(1)}>Abiertos</option>
                                            <option value={0} onClick={() => setFilterClosed(0)}>Cerrados</option>
                                        </select>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                    </p>
                    
                </div>

                <div className="field is-grouped">

                <p className="control">
                    <div className="field is-horizontal">
                        <div className="field-label is-small">
                            <label className="label">Desde</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <DatePicker 
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        customInput={<ExampleCustomInput />}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </p>

                <p className="control">
                    <div className="field is-horizontal">
                        <div className="field-label is-small">
                            <label className="label">Hasta</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <DatePicker 
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        customInput={<ExampleCustomInput />}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </p>

                </div>
                    <button className="button" onClick={e => getTickets(null)} style={{marginRight:'8px', backgroundColor: '#ff7143', color: '#FFF', border: 0}}>
                        <IonIcon icon={search} />
                        <div style={{transform: 'translateY(0px)', marginLeft: '8px', fontWeight: 'bold'}}>Buscar</div>
                    </button>
            </div>

            <div className="box" style={{borderRadius: '0', border: '1px solid #eeeeee'}}>
                <div style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '12px'}}>
                            <div style={{float: 'left', marginRight: '8px', transform: 'translateY(3px)'}}>
                                <IonIcon icon={search} />
                            </div>
                            {registersCount} Resultados
                        </div>
                    
                        <Pagination 
                    getTickets={getTickets}
                    currentPage={currentPage}
                    pageCount={pageCount}
                />
                <div className="table-container">
                    <table className="table is-bordered is-narrow is-hoverable is-fullwidth" style={{fontSize: '12px'}}>
                        <thead style={{backgroundColor: '#f5f5f5'}}>
                            <tr>
                                <th style={{width: '24px', textAlign: 'center'}}>P</th>
                                {/* <th><abbr title="ID">ID</abbr></th> */}
                                <th style={{width: '128px'}}>Categoria</th>
                                <th style={{width: '64px'}}><abbr title="Código">Código</abbr></th>
                                <th>Asunto</th>
                                <th style={{width: '180px'}}>Publicado por</th>
                                <th style={{width: '180px'}}>Empresa</th>
                                <th style={{width: '128px'}}>Estado</th>
                                <th style={{width: '180px'}}>Asignado a</th>
                                {/* <th>Responsable</th> */}
                                {/* <th>Última Respuesta</th> */}
                                <th style={{width: '98px', textAlign: 'center'}}>Creado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(tickets) && tickets.map(ticket =>
                                <tr key={ticket.id}>
                                    <td style={{textAlign: 'center'}}>
                                        {ticket.priority === 1 && <div className="priority-icon low"></div>}
                                        {ticket.priority === 2 && <div className="priority-icon mid"></div>}
                                        {ticket.priority === 3 && <div className="priority-icon high"></div>}
                                    </td>
                                    <td style={{fontWeight: 'bold'}}>
                                        {ticket.is_incident ?
                                        <div style={{color:'#ff3860'}}>{ticket.category.name}</div>:
                                        <div style={{color:'#48c774'}}>{ticket.category.name}</div>}
                                    </td>
                                    {/* <th><abbr title="ID">{ticket.id}</abbr></th> */}
                                    <td style={{fontWeight: 'bold'}}><Link to={'/tickets/' + ticket.id} target="_blank" rel="noreferrer">{ticket.code}</Link></td>
                                    <td>
                                        {!ticket.open &&
                                            <div className="expiration-answer-icon locked" style={{display: 'inline-block', transform: 'scale(130%) translateY(2px)', marginRight: '4px'}} />
                                        }
                                        {ticket.subject}
                                    </td>
                                    <td>{ticket.sender.name + ' ' + ticket.sender.lastname}</td>
                                    <td>{ticket.enterprise ? ticket.enterprise.name : ''}</td>
                                    {/* <td>{ticket.enterprise}</td> */}
                                    <td>
                                        {ticket.status === 1 && <div className="priority-mark pending">Pendiente</div>}
                                        {ticket.status === 2 && <div className="priority-mark in-progress">En Progreso</div>}
                                        {ticket.status === 3 && <div className="priority-mark answered">Respondido</div>}
                                        {ticket.status === 4 && <div className="priority-mark waiting">En Espera</div>}
                                        {ticket.status === 5 && <div className="priority-mark solved">Resuelto</div>}
                                        {ticket.status === 6 && <div className="priority-mark finished">Finalizado</div>}
                                        {ticket.status === 7 && <div className="priority-mark closed">Cerrado</div>}
                                    </td>
                                    <td>{ticket.owner ? ticket.owner.name + ' ' + ticket.owner.lastname : ' - '}</td>
                                    {/* <td>{ticket.ticket_manager ? ticket.ticket_manager.name + ' ' + ticket.ticket_manager.lastname : ' - '}</td> */}
                                    {/* <td>{ticket.last_answer && ticket.last_answer.name}</td> */}
                                    <td style={{textAlign: 'center'}}>{new Date(ticket.created_at).toLocaleString('es-ar')}</td>
                                </tr>
                            )}
                        </tbody>
                        
                    </table>
                </div>

                <Pagination 
                    getTickets={getTickets}
                    currentPage={currentPage}
                    pageCount={pageCount}
                />

            </div>
        </Layout>
    )
}

export { TicketFinderPage };