import React from "react";
import moment from "moment/moment";
import 'moment/locale/es';
import { toast, Bounce } from "react-toastify";

// Create Context 
const AppContext = React.createContext();

// Provider
function AppProvider(props){

    const API_ADDRESS = 'https://api.soporte.mesadeserviciosit.com/api/'
    const MEDIA_ADDRESS = 'https://api.soporte.mesadeserviciosit.com'

    // const API_ADDRESS = 'http://localhost:9000/api/'
    // const MEDIA_ADDRESS = 'http://localhost:9000'

    //const API_ADDRESS = 'http://10.0.0.99:9000/api/'
    //const MEDIA_ADDRESS = 'http://10.0.0.99:9000'

    // Context States
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [isLoginPage, setIsLoginPage] = React.useState(false);
    const [myProfile, setMyProfile] = React.useState({});

    // Context Functions
    const getToken = () => {
        const localStorageToken = localStorage.getItem('token');
        if (!localStorageToken){
            localStorage.setItem('token', '')
            return ('');
        } else {
            return (localStorageToken);
        }
    }

    function saveToken(text){
        localStorage.setItem('token', text);
    }

    function deleteToken() {
        localStorage.removeItem('token');
    }

    const guestRedirect = () => {
        if (getToken() === ''){
            window.location.href = '/login?next=' + window.location.pathname;
        }
    }

    function momentDate(mydate) {
        const momentedDate = moment(mydate).local().startOf('seconds').fromNow();
        return momentedDate;
    }

    function momentDateNormal(mydate) {
        const momentedDate = moment(mydate).format('LLL');
        return momentedDate;
    }

    const notify_options = {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    }

    const notify = (notification, type) => {
        if (type === 'info') {
            toast.info(notification, notify_options)
        }
        else if (type == 'success') {
            toast.success(notification, notify_options)
        }
        else if (type == 'warn') {
            toast.warn(notification, notify_options)
        }
        else if (type == 'error') {
            toast.error(notification, notify_options)
        }
        else {
            toast(notification, notify_options)
        }
    }

    // Render Countdown
    const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (days > 0) {
            return  <div className="expiration-timer">
                        {days}D {hours}h
                    </div>
        } else {
            if (hours > 0) {
                return  <div className="expiration-timer">
                            {hours}h {minutes}m
                        </div>
            } else {
                if (minutes > 0) {
                    return  <div className="expiration-timer almost_expired">
                                {minutes}m {seconds}s
                            </div>
                } else {
                    if (seconds > 0) {
                        return  <div className="expiration-timer almost_expired">
                                    0m {seconds}s
                                </div>
                    } else {
                        return  <div className="expiration-timer expired">
                                    Vencido
                                </div>
                    }
                }
            }
        }
    }

    // Context Provider Return
    return(
        <AppContext.Provider value={{
            // Settings
            API_ADDRESS,
            MEDIA_ADDRESS,

            // Token Related
            getToken,
            saveToken,
            deleteToken,

            // Login Page,
            isLoginPage,
            setIsLoginPage,
            guestRedirect,

            // Profile Related
            myProfile,
            setMyProfile,

            // Page
            loading,
            setLoading,
            error,
            setError,
            
            // Functions
            momentDate,
            momentDateNormal,

            // Component Utils
            countdownRenderer,

            // Notification
            notify,
        }}>
            {props.children}
        </AppContext.Provider>
    )
}
export {AppContext, AppProvider};