import React from "react";
import './style.css';

function Pagination(props){
    return(
        <nav className="pagination is-centered" role="navigation" aria-label="pagination">
                    <a className={"pagination-previous " + ((props.currentPage === 0 || props.currentPage === 1) && "is-disabled")} onClick={() => props.getTickets(props.currentPage - 1)}>Anterior</a>
                    <a className={"pagination-next " + ((props.currentPage === props.pageCount || props.pageCount === 0) && "is-disabled")} onClick={() => props.getTickets(props.currentPage + 1)}>Siguiente</a>
                    <ul className="pagination-list">
                        {props.pageCount === 0 || props.pageCount === 1 && 
                            <li><a className="pagination-link is-current" aria-label={'Página '+ 1} aria-current="page">1</a></li>
                        }
                        {props.pageCount > 1 &&
                            <React.Fragment>
                                {props.pageCount === 1 &&
                                    <li><a className="pagination-link is-current" aria-label={'Pagina ' + props.currentPage} aria-current="page">{props.currentPage}</a></li>
                                }
                                {props.pageCount === 2 && props.currentPage == 1 &&
                                    <React.Fragment>
                                        <li><a className="pagination-link is-current" aria-label={'Pagina ' + props.currentPage} aria-current="page">{props.currentPage}</a></li>
                                        <li><a className="pagination-link" aria-label={'Ir a la pagina ' + props.pageCount} onClick={() => props.getTickets(2)}>{props.pageCount}</a></li>
                                    </React.Fragment>
                                }
                                {props.pageCount === 2 && props.currentPage == 2 &&
                                    <React.Fragment>
                                        <li><a className="pagination-link" aria-label={'Ir a la pagina ' + props.pageCount - 1} onClick={() => props.getTickets(1)}>{props.pageCount - 1}</a></li>
                                        <li><a className="pagination-link is-current" aria-label={'Pagina ' + props.currentPage} aria-current="page">{props.currentPage}</a></li>
                                    </React.Fragment>
                                }
                                {props.pageCount > 2 &&
                                    <React.Fragment>
                                        <li>
                                            <a 
                                                className={"pagination-link " + (props.currentPage === 1 && "is-current")} 
                                                aria-label={'Ir a la pagina ' + props.pageCount - 1} 
                                                onClick={() => {
                                                    props.currentPage !== 1 && props.getTickets(1);
                                                    }
                                                }>
                                                    1
                                            </a>
                                        </li>
                                        {props.currentPage > 2 &&
                                            <li><span className="pagination-ellipsis">&hellip;</span></li>
                                        }
                                        {props.currentPage !== 1 && props.currentPage !== props.pageCount && 
                                            <li><a className="pagination-link is-current" aria-label={'Pagina ' + props.currentPage} aria-current="page">{props.currentPage}</a></li>
                                        }
                                        {props.currentPage < props.pageCount - 2 || props.currentPage === 1 &&
                                            <li><span className="pagination-ellipsis">&hellip;</span></li>
                                        }
                                        <li>
                                            <a 
                                                className={"pagination-link " + (props.currentPage === props.pageCount && "is-current")} 
                                                aria-label={'Ir a la pagina ' + props.pageCount}
                                                onClick={() => {
                                                    if (props.currentPage !== props.pageCount) {
                                                        props.getTickets(props.pageCount);
                                                    }
                                                }}>
                                                {props.pageCount}
                                            </a>
                                        </li>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </ul>
                </nav>
    )
}

export { Pagination };