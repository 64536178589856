// React
import React from "react";
import axios from "axios";

// Context
import { AppContext } from "../../../../layout/AppContext";

// Icons
import { IonIcon } from "@ionic/react";
import { ticketOutline } from 'ionicons/icons';

function AllCanISeeTicketsClient(props) {
    
    const [loading, setLoading ] = React.useState(false)
    const {API_ADDRESS, getToken} = React.useContext(AppContext);

    async function getTickets(){
        setLoading(true)
        props.saveListWhat('all_i_can_see')
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/',
            headers: { Authorization: 'Token ' + getToken()}
        })
        .then(function (response) {
            props.setTickets(response.data);
            setLoading(false);
        })
        .catch(function (err) {
            setLoading(false);
            console.log(err)
        });
    }

    return(
        <div id={'all_i_can_see'} className="btn-tickets" onClick={() => getTickets() }>
            <IonIcon icon={ticketOutline} /> <span> &nbsp; Tickets de la Empresa</span>
        </div>
    );
}

export { AllCanISeeTicketsClient };