// React
import React, { useCallback } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import { IonIcon } from "@ionic/react";
import { ticket } from 'ionicons/icons'

// Layout
import { Layout } from "../../../layout/Layout";

import { AppContext } from "../../../layout/AppContext";

import { LoadingComponent } from "../../Index/LoadingComponent";

import './TicketNew.css';

import { useDropzone } from "react-dropzone";

function TicketNewPage(props) {

    const {API_ADDRESS, MEDIA_ADDRESS, getToken, loading, setLoading, error, setError, guestRedirect, myProfile, setMyProfile, notify} = React.useContext(AppContext);
    const navigate = useNavigate();

    const [profiles, setProfiles] = React.useState([]);
    const [categories, setCategories] = React.useState();
    
    const [isIncident, setIsIncident] = React.useState(false);
    const [subject, setSubject] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [contact, setContact] = React.useState({});
    const [category, setCategory] = React.useState("0");
    const [priority, setPriority] = React.useState("1");
    const [listOfFiles, setListOfFiles] = React.useState([]);

    const [enterpriseSelected, setEnterpriseSelected] = React.useState();
    const [categorySelected, setCategorySelected] = React.useState();

    const [bussinessPermision, setBussinessPermission] = React.useState();

    const [loadingAssistant, setLoadingAssistant] = React.useState(true);
    // STEPS
    const [stepSelectEnterprise, setStepSelectEnterprise] = React.useState(true);
    const [stepSelectCategory, setStepSelectCategory] = React.useState(false);
    const [stepCreateTicket, setStepCreateTicket] = React.useState(false);


    async function getProfiles(query){
        setLoading(true)
        await axios({
            method: 'get',
            url: API_ADDRESS + 'profiles/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                find_profile: true,
                query: query
            }
        })
        .then(function (response) {
            setProfiles(response.data);
        })
        .catch(function (err) {
            setLoading(false);
        });
    }

    async function getCategories(enterprise_id){
        setLoading(true)
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/category/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                enterprise: enterprise_id,
            }
        })
        .then(function (response) {
            setCategories(response.data);
        })
        .catch(function (err) {
            setLoading(false);
        });
    }

    async function postTicket(){
        setLoading(true)
        setError(false)
        await axios({
            method: 'post',
            url: API_ADDRESS + 'tickets/',
            headers: { Authorization: 'Token ' + getToken()},
            data: {
                is_incident: isIncident,
                subject: subject,
                message: message,
                // sender: contact.id,
                category: category,
                priority: priority,
                attach: listOfFiles,
                enterprise: enterpriseSelected.id,
            }
        })
        .then(function (response) {
            navigate('/tickets/' + response.data.id);
            // navigate('/tickets');
        })
        .catch(function (err) {
            // notify('Algo no funcionó', 'error')
            setError(true)
            setLoading(false);
        });
    }

    const [contactInput, setContactInput] = React.useState('');

    function contactQuery(query) {
        setContactInput(query);
        getProfiles(query);
    }

    async function postNewContactFast(){
        setLoading(true)
        await axios({
            method: 'post',
            url: API_ADDRESS + 'profiles/',
            headers: { Authorization: 'Token ' + getToken()},
            data: {
                name: contactInput
            }
        })
        .then(function (response) {
            setContact(response.data);
            setContactInput("");
        })
        .catch(function (err) {
            setLoading(false);        
        });
    }

    const [isUploadingFile, setIsUploadingFile] = React.useState(false)

    async function postTicketAttach(file, filename){
        // const file = files
        const formData = new FormData();
        formData.append("file", file)
        formData.append("filename", filename)
        setIsUploadingFile(true);
        await axios({
            method: 'post',
            url: API_ADDRESS + 'tickets/ticket_attach/',
            headers: {
                'Authorization': 'Token ' + getToken(),
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        }) .then(function (response) {
            setIsUploadingFile(false);
            setListOfFiles(current => [...current, response.data.id])
        })
        .catch(function (err) {
            setIsUploadingFile(false);
        });
    }
    
    const [uploadedFiles, setUploadedFiles] = React.useState([]);

    const handleDrop = (acceptedFiles) => {
        if (uploadedFiles.length === 0) {
            setUploadedFiles(acceptedFiles)
        } else {
            setUploadedFiles(uploadedFiles.concat(acceptedFiles));
        }
        acceptedFiles.map(file => postTicketAttach(file, file.name));
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'text/plain': ['.txt'],
            'application/pdf': ['.pdf'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/zip': ['.zip'],
            'application/vnd.rar': ['.rar'],
        },
        onDrop: handleDrop
    });
    
    async function getMyEnterprises() {
        setLoading(true)
        await axios({
            method: 'get',
            url: API_ADDRESS + 'profiles/bussiness_permission/',
            headers: { Authorization: 'Token ' + getToken()}
        })
        .then(function (response){
            setLoading(false);
            setBussinessPermission(response.data);
            setLoadingAssistant(false);
        })
        .catch(function (err) {
            setLoading(false);
        })
    }
    
    async function getMyProfile() {
        try {
            let response = await axios({
                method: 'get',
                url: API_ADDRESS + 'profiles/',
                headers: { Authorization: 'Token ' + getToken()}
            });
            if (response.status === 404) {
                navigate('/404');
            }
            if (response.status === 200) {
                if (response.data.client_profile) {
                    navigate('/404');
                } else {
                    
                }
            }
        }
        catch (e) {}
    }

    React.useEffect( () => {
        guestRedirect();
        getMyProfile();
        getMyEnterprises();
    }, [])

    return (
        <Layout>
            <div style={{
                width: '100%',
                height: '100%',
                zIndex: 10,
                backgroundColor: '#FFF',
                position: 'absolute',
                display: loadingAssistant ? 'block' : 'none',
            }}
            >
                <LoadingComponent />
            </div>
            {stepSelectEnterprise &&
             <div className="box" 
                style={{
                    border: '2px solid #5a6672',
                    borderRadius: '8px',
                    marginBottom: '8px',
                    padding: '12px 12px 32px 12px'
                }}
                >
                <span className="title is-4">&nbsp;&nbsp;Nuevo Ticket - Seleccionar Empresa</span>
                <div style={{height: '12px'}}></div>
                {bussinessPermision  && bussinessPermision.enterprise.map(enterprise =>
                            <div
                                key={enterprise.id}
                                onClick={() => {
                                    setStepSelectEnterprise(false);
                                    setStepSelectCategory(true);
                                    setEnterpriseSelected(enterprise);
                                    getCategories(enterprise.id);
                                }}
                                className="enterprise-selection-btn"
                            >
                                <div
                                    style={enterprise.avatar && {marginBottom: '32px'}} 
                                    className={enterprise.avatar ? "avatar-enterprise-big has-background-transparent" : "avatar-enterprise-big" }>
                                    {enterprise.avatar ?
                                        <img src={MEDIA_ADDRESS + enterprise.avatar}/>
                                    :
                                        <span>{enterprise.name.slice(0,2)}</span>
                                    }
                                </div>
                                <div style={{width: '200px', height: '44px', display: 'inline'}}>
                                {enterprise.name}
                                </div>
                            </div>
                )}
            </div>
            }
            {stepSelectCategory &&
                <div className="box" 
                    style={{
                        border: '2px solid #5a6672',
                        borderRadius: '8px',
                        marginBottom: '8px',
                        padding: '12px 12px 32px 12px'
                    }}
                >
                    <span className="title is-4">&nbsp;&nbsp;Nuevo Ticket - Seleccionar Categoria</span>
                    {categories  && categories.map(cat =>
                    <div className="columns" style={{margin: '0 0 -16px 0'}}>
                        <div className="column is-12">
                            <div 
                                key={cat.id}
                                onClick={() => {
                                    // Ticket Options
                                    setIsIncident(cat.is_incident);
                                    setPriority(cat.priority);
                                    setCategory(cat.id);
                                    setCategorySelected(cat);
                                    // Step Change
                                    setStepSelectCategory(false);
                                    setStepCreateTicket(true);
                                }}
                                className="category-selection-btn"
                            >
                                <span style={{fontFamily: '"MadeTommy-SoftBold"', fontSize: "16px"}}>{cat.name}</span>
                                <span style={{fontSize: '14px'}}>
                                    {cat.description}
                                </span>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            }
            {stepCreateTicket &&
                <div className="box" 
                style={{
                    border: '2px solid #5a6672',
                    borderRadius: '8px',
                    marginBottom: '8px',
                    padding: '12px 12px 5px 12px'
                }}
                >
                    <span className="title is-4">Nuevo Ticket</span>
                    <br />
                    <div className="avatar-mini-enterprise">
                        {enterpriseSelected.avatar ?
                        <img src={MEDIA_ADDRESS + enterpriseSelected.avatar}/>
                        :<span>{enterpriseSelected.name.slice(0,2)}</span>
                        }
                    </div>
                    <span>{enterpriseSelected && enterpriseSelected.name}</span>
                    <br />
                    <span style={{fontSize:'14px'}}><strong>Categoria:</strong> {categorySelected && categorySelected.name}</span>

                    <div className="field" style={{marginTop: '8px'}}>
                        <div className="control is-small">
                            <input className="input is-small" type="text" placeholder="Asunto" 
                                onChange={e => {setSubject(e.target.value)}}
                            />
                        </div>
                    </div>
                    <textarea className="textarea is-small" placeholder="Descripción"
                        onChange={e => {setMessage(e.target.value)}}
                    ></textarea>

                    <div className="columns">
                        <div className="column">
                                <div {...getRootProps()}
                                    className="upload_attach"
                                    style={{marginTop: '8px', marginBottom: '8px'}}
                                >
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive ?
                                        <p>Drop the files here ...</p> :
                                        <p>Agrega archivos adjuntos aquí</p>
                                    }
                                </div>
                                <ul>
                                    {uploadedFiles.map((file) => (
                                        <React.Fragment>
                                        <li className="attach_link_new" key={file.name}>{file.name}</li>
                                        <br />
                                    </React.Fragment>
                                    ))}
                                </ul>
                        </div>
                        <div className="column">
                            <button 
                                className={
                                    isUploadingFile ?
                                    "button is-info is-small is-pulled-right is-loading is-disabled" :
                                    "button is-info is-small is-pulled-right"
                                }
                                onClick={() => postTicket()} style={{marginTop: '8px'}}
                            >
                                <span className="icon is-small">
                                    <IonIcon icon={ticket} />
                                </span>
                                <span>Crear ticket</span>
                            </button>
                        </div>
                    </div>

                    {error &&
                        <div className="has-text-danger" style={{width: "100%", marginTop: "0px"}}>
                            Todos los campos son obligatorios.
                        </div>
                    }
                </div>
            }
        </Layout>
    )
}

export { TicketNewPage };