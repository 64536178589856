// React
import React from "react";

// Context
import { AppContext } from "../../../../layout/AppContext";

function TicketSender(props){
    const { MEDIA_ADDRESS } = React.useContext(AppContext);
    
    return(
        <div
            className="box"
            style={{
                border: '2px solid #5a6672',
                borderRadius: '8px',
                marginBottom: '8px',
                padding: '12px 12px 5px 12px'
            }}
        >
            <div className="columns is-mobile" style={{marginBottom: '-12px'}}>
                <div className="column" style={{ maxWidth: '64px'}}>
                    <div className="avatar">
                    {
                        props.ticket && props.ticket.sender && props.ticket.sender && props.ticket.sender.avatar ?
                        <img src={MEDIA_ADDRESS + props.ticket.sender.avatar}/> :
                        <span>{props.ticket.sender && props.ticket.sender.name.slice(0,1) + props.ticket.sender.lastname.slice(0,1)}</span>
                    }
                    </div>
                </div>
                <div className="column" style={{ maxWidth: '500px', paddingLeft: 0}}>
                    <h6 className="title is-6" style={{marginTop: '4px'}}>
                        <span>
                            <strong style={{fontFamily: 'MadeTommy-Regular'}}>{props.sender && (props.sender.name + ' ' + props.sender.lastname)}</strong>
                        </span>
                        <br />
                        <strong className="has-text-link">{props.sender && props.sender.enterprise.name}</strong>
                        <strong>
                            &nbsp;({props.sender && props.sender.bussiness_function})
                        </strong>
                    </h6>
                </div>
                
            </div>
        </div>
    );
}

export { TicketSender };