import React from "react";

import './LoadingComponent.css';

function LoadingComponent(props){
    return(
        <div style={{
            width: '80px',
            height: '80px',
            position: 'absolute',
            top: (window.screen.height / 2) - 80,
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export { LoadingComponent };