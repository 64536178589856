// React
import React from "react";
import axios from "axios";

// Context
import { AppContext } from "../../../../layout/AppContext";

// Icons
import { IonIcon } from "@ionic/react";
import { arrowRedoOutline } from 'ionicons/icons';

function CreatedByMeClient(props) {

    const [loading, setLoading ] = React.useState(false)
    const {API_ADDRESS, getToken} = React.useContext(AppContext);

    async function getTickets(){
        setLoading(true)
        props.saveListWhat('created_by_me')
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                mine_tickets: true
            }
        })
        .then(function (response) {
            props.setTickets(response.data);
            setLoading(false);
        })
        .catch(function (err) {
            setLoading(false);
            console.log(err)
        });
    }

    return(
        <div id={'created_by_me'} className="btn-tickets" onClick={() => getTickets() }>
            <IonIcon icon={arrowRedoOutline} /> <span> &nbsp; Mis Tickets</span>
        </div>
    );
}

export { CreatedByMeClient };