
// React
import React from "react";

// Layout
import { Layout } from "../../layout/Layout";

// Styles
import './Style404.css';

function Page404() {
    return(
        <Layout>
            <div className="container404">
                <div className="error404">
                    Error <strong>404</strong>
                    <br />
                    <br />
                    Lo sentimos. La dirección a la que querés ir no se encuentra <strong>:(</strong>
                </div>
                <div className="image404 shadow-pop-tr">
                    
                </div>
            </div>
        </Layout>
    )
}

export { Page404 };