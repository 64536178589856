// React
import React from "react";

// Columns
import { NavBar, ColumnMenu, ColumnSpaceWork } from "../Columns";

// Notification
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Layout(props) {
    return (
        <React.Fragment>
            <ToastContainer />
            <NavBar />
            <div style={{
                width: '100%',
                padding: '0 16px 0 16px'
            }}>
                <div className="columns">
                    {/* <ColumnMenu /> */}
                    <ColumnSpaceWork>
                        <div 
                            className={props.noContainer === true ? '' : 'container'}
                            style={{marginTop: '16px'}}
                        >
                            {props.children}
                        </div>
                    </ColumnSpaceWork>
                </div>
            </div>
        </React.Fragment>
    )
}

export { Layout };