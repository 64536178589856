export default {
    input: {fontWeight: "bold"},
    control: {
      backgroundColor: '#FFF',
      fontSize: 16,
      // fontWeight: 'normal',
    },
    '&multiLine': {
      control: {
        padding: "12px 14px",
        minHeight: 128,
        marginBottom: 12,
      },
      highlighter: {
        padding: 0,
        border: '1px solid transparent',
        // zIndex: "2",
        display: 'item-list block',
      },
      input: {
        padding: "14px 12px",
        border: '1px solid silver',
      },
    },
    '&singleLine': {
      display: 'inline-block',
      width: 180,
      highlighter: {
        padding: 1,
        border: '2px inset transparent',
      },
      input: {
        padding: 1,
        border: '2px inset',
      },
    },
    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 16,
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#cee4e5',
        },
      },
    },
  }