// React
import React from "react";
import { useNavigate } from "react-router-dom";

// import './Index.css';

import { Layout } from "../../layout/Layout";
import axios from "axios";
import { AppContext } from "../../layout/AppContext";

import { LoadingComponent } from "./LoadingComponent";

function IndexPage(props) {

    // Primary
    let navigate = useNavigate();

    const { API_ADDRESS, getToken, guestRedirect} = React.useContext(AppContext);

    async function getMyProfile() {
        try {
            let response = await axios({
                method: 'get',
                url: API_ADDRESS + 'profiles/',
                headers: { Authorization: 'Token ' + getToken()}
            });
            if (response.status === 404) {
                navigate('/404');
            }
            if (response.status === 200) {
                if (response.data.technisian_profile) {
                    navigate('/tickets');
                }
                else if (response.data.staff_profile) {
                    navigate('/tickets');
                }
                else if (response.data.client_profile) {
                    navigate('/clientarea');
                } else {
                    navigate('/404')
                }
            }
        }
        catch (e) {}
    }

    React.useEffect(() => {
        guestRedirect();
        getMyProfile();
    }, []);

    return (
        <Layout>
            {/* <LoadingComponent /> */}
        </Layout>
    )
}

export { IndexPage };