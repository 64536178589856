// React
import React from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import { IonIcon } from "@ionic/react";

import { star, checkboxOutline, closeOutline } from "ionicons/icons";

import { AppContext } from "../../../../layout/AppContext";

import fileDownload from "js-file-download";

import { NewMessage } from "./NewMessage";
import { NewInternalNote } from "./InternalNote";
import { NewAuthorization } from "./Autorization";

import { toast, Bounce } from "react-toastify";

import './TicketMessages.css'

function TicketMessages(props){
//     const fileInput = document.querySelector('#file-js-example input[type=file]');
//   fileInput.onchange = () => {
//     if (fileInput.files.length > 0) {
//       const fileName = document.querySelector('#file-js-example .file-name');
//       fileName.textContent = fileInput.files[0].name;
//     }
//   }
    

    const navigate = useNavigate();
    // Context
    const { 
        getToken,
        API_ADDRESS,
        loading,
        setLoading,
        error,
        momentDate,
        MEDIA_ADDRESS,
        notify,
    } = React.useContext(AppContext);

    const regex = /@\[(.*)\]/g;

    // Component States
    const [messages, setMessages] = React.useState([]);

    const [newMessageOpen, setNewMessageOpen] = React.useState(false);
    const [typeNewMessage, setTypeNewMessage] = React.useState('message')

    const [myProfile, setMyProfile] = React.useState({});

    // Component Functions
    async function getMessages(idticket){
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/messages/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                idticket: idticket
            }
        })
        .then(function (response) {
            setMessages(response.data)
        })
        .catch(function (err) {
        });
    }

    async function getMyProfile() {
        try {
            let response = await axios({
                method: 'get',
                url: API_ADDRESS + 'profiles/',
                headers: { Authorization: 'Token ' + getToken()}
            });
            if (response.status === 404) {
                navigate('/404');
            }
            if (response.status === 200) {
                setMyProfile(response.data);
            }
        }
        catch (e) {}
    }

    

    async function getFile(id, filename){
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/download/' + id + '/',
            headers: { Authorization: 'Token ' + getToken()},
            responseType: 'blob'
        })
        .then(function (response) {
            fileDownload(response.data, filename)
        })
        .catch(function (err) {});
    }

    async function authorizeMessage(id_message, what_do){
        await axios({
            method: 'post',
            url: API_ADDRESS + 'tickets/messages/authorize/',
            headers: { Authorization: 'Token ' + getToken()},
            data: {
                message: id_message,
                what_do: what_do,
            }
        })
        .then(function (response) {
            if (response.data === 'accepted') {
                notify('Has autorizado el servicio', 'success')
            }
            if (response.data === 'rejected') {
                notify('Has rechazado el servicio', 'warn')
            }
            getMessages(props.ticket.id);            
        })
        .catch(function (err) {
            if (err.response.data === 'unauthorized'){
                notify('No tienes permiso para autorizar', 'error');
            }
        })
    }

    React.useEffect(() => {
        if (props.ticket != {}) {
            getMessages(props.ticket.id);
        }
        getMyProfile();
    }, [props.ticket]);

    return(
        <React.Fragment>
            <div style={{height: '8px'}}></div>
            <div
                className="box"
                style={{
                    border: '2px solid #5a6672',
                    borderRadius: '8px',
                    marginBottom: '8px',
                    padding: '12px 12px 5px 12px'
                }}
            >
                <span className="title is-5">Mensajes</span>
                {props.ticket.open &&
                <div 
                    className="box"
                    style={{
                        padding: '8px',
                        border: '1px solid #BBB',
                        borderRadius: 0,
                        fontWeight: 'bold',
                        margin: '8px 0 8px 0',
                    }}
                >
                    <div className="austero-menu-item"
                        onClick={() => {
                            setTypeNewMessage('message')
                            setNewMessageOpen(true);
                        }}
                    >Responder ticket</div>
                    <div className="austero-menu-item" style={{color: '#212121'}}>/</div>
                    <div className="austero-menu-item"
                        onClick={() => {
                            setTypeNewMessage('internal')
                            setNewMessageOpen(true);
                        }}
                    >Crear nota interna</div>
                    <div className="austero-menu-item" style={{color: '#212121'}}>/</div>
                    <div className="austero-menu-item"
                        onClick={() => {
                            setTypeNewMessage('authorization')
                            setNewMessageOpen(true);
                        }}
                    >Requerir Autorizacion</div>
                </div>
                }

                <div 
                    style={{
                        borderBottom: '1px solid #afb7c1',
                        margin:'12px 0 8px 0'
                    }}>
                </div>
                
                <NewMessage
                    newMessageOpen={newMessageOpen}
                    setNewMessageOpen={setNewMessageOpen}
                    typeNewMessage={typeNewMessage}
                    setTypeNewMessage={setTypeNewMessage}
                    ticket={props.ticket}
                    getMessages={getMessages}
                />

                {/* <NewAuthorization
                    newAuthorizationOpen={newAuthorizationOpen}
                    setNewAutorizationOpen={setNewInternalNoteOpen}
                    ticket={props.ticket}
                    getMessages={getMessages}
                /> */}

                {messages.length === 0 && <span style={{fontSize: '12px'}}>No hay mensajes</span>}

                {messages.map(message => 
                
                    <div 
                    className={
                               message.type && message.type === "message" ? "box bgMessage" :
                               message.type === "internal" ? "box bgInternal" :
                               message.type === "authorization" ? "box bgAuthorization" : "box bgMessage"
                              }          
                    key={message.id}
                    style={
                        {
                        borderRadius: 0,
                        marginBottom: '8px',
                        whiteSpace: "pre-wrap",
                        padding: 0
                    }}
                    >
                    
                    <div>
                        <div className="avatar-mini">
                            {
                                message.created_by && message.created_by.avatar ?
                                <img src={MEDIA_ADDRESS + message.created_by.avatar}/> :
                                <span>
                                    {message.created_by && message.created_by.name.slice(0,1) + message.created_by.lastname.slice(0,1)}
                                </span>
                            }
                        </div>
                        <div
                            style={
                                message.created_by.avatar
                                ?
                                {
                                    display: 'inline-grid',
                                    fontWeight: 'bold',
                                    transform: 'translateY(-7px)'
                                }
                                :
                                {
                                    display: 'inline-grid',
                                    fontWeight: 'bold',
                                }
                            }
                        >
                            {message.created_by && message.created_by.name + " " + message.created_by.lastname}
                        </div>
                        
                        <div 
                        className="is-size-7 ml-2"
                        style={
                            message.created_by.avatar
                            ?
                            {
                                display: 'inline-grid',
                                transform: 'translateY(-7px)'
                            }
                            :
                            {
                                display: 'inline-grid',
                                fontWeight: 'bold',
                            }
                        }
                    >
                        ({momentDate(message.created_at)})
                        
                    </div>
                    
                    </div>
                    
                    {message.type === 'internal' &&
                        <React.Fragment>
                            <IonIcon icon={star} style={{fontSize: 12}}/>
                            <span style={{fontSize: 12}}>
                                &nbsp;Nota interna
                            </span>
                        </React.Fragment>
                    }
                    {message.type === 'authorization' &&
                        <React.Fragment>
                            <IonIcon icon={star} style={{fontSize: 12}}/>
                            <span style={{fontSize: 12}}>
                                &nbsp;Autorización
                            </span>
                        </React.Fragment>
                    }

                    {/* <ContactBox sender={message.created_by} isMessage={true}/> */}
                    <div style={{paddingBottom: '12px' ,fontSize: '15px'}}>
                        
                        {message.message.replace(regex, `$1`)}
                        
                        {message.ticket_attach.length > 0 && 
                            <React.Fragment>
                                <br />
                                <span className="is-size-7" style={{fontWeight: 'bold',fontFamily: 'MadeTommy-SoftBold', }}>Archivos adjuntos</span>
                                <br />
                                {message.ticket_attach &&
                                message.ticket_attach.map(att => 
                                    <React.Fragment key={att.id}>
                                        {/* <a href={MEDIA_ADDRESS + att.file}> */}
                                        <div className='attach_link_message' onClick={() => getFile(att.id, att.file_name)}>
                                            {att.file_name}
                                        </div>
                                        <br />
                                    </React.Fragment>
                                )}
                            </React.Fragment>}
                        </div>
                        

                    {
                        message.type === 'authorization' &&
                        <React.Fragment>
                            {message.authorized_at === null ?
                            <div style={{borderTop: '1px solid #afb7c1', paddingTop: '8px'}}>
                            <button className="button is-success is-small is-responsive" onClick={() => authorizeMessage(message.id, 'accepted')}>
                                Autorizar
                            </button>
                            <button style={{marginLeft: '8px'}} className="button is-danger is-small is-responsive" onClick={() => authorizeMessage(message.id, 'rejected')}>
                                Rechazar
                            </button>
                        </div> : 
                        <div style={{fontSize: '12px', fontWeight: 'bold', borderTop: '1px solid #afb7c1', paddingTop: '8px'}}>
                            {message.authorized && message.authorized === 'accepted' && <IonIcon icon={checkboxOutline} style={{fontSize: 14, transform: 'translateY(2px)', color: 'green'}}/>}
                            {message.authorized && message.authorized === 'rejected' && <IonIcon icon={closeOutline} style={{fontSize: 14, transform: 'translateY(2px)', color: 'red'}}/>}
                            {message.authorized && message.authorized === 'accepted' && ' Autorizado por: ' }
                            {message.authorized && message.authorized === 'rejected' && ' Rechazado por: ' }
                            {message.authorized_by.name + " " + message.authorized_by.lastname}
                            <br />
                            {momentDate(message.authorized_at)}
                        </div>
                            }
                        </React.Fragment>
                    }

                    </div>
                    
)}

            </div>      
        </React.Fragment>
    )
}

export { TicketMessages };