import React from "react";
import axios from "axios";
import { AppContext } from "../../layout/AppContext";
import { useNavigate } from "react-router-dom";

function LoginFunctions() {
    
    // Primary
    let navigate = useNavigate();

    // AppContext
    const {
        API_ADDRESS,
        saveToken,
        setLoading,
        setError,
        loading,
        error,
    } = React.useContext(AppContext);

    // Component States
    const [loginUsername, setLoginUsername] = React.useState('');
    const [loginPassword, setLoginPassword] = React.useState('');

    const [nextPage, setNextPage] = React.useState('/');

    // Component Functions
    async function login() {
        setLoading(true);
        try {
          let response = await axios({
              method: 'post',
              url: API_ADDRESS + 'login/',
              data: {
                  username: loginUsername,
                  password: loginPassword
              }
          });
        //   saveUserFullName(response.data.user.first_name + ' ' + response.data.user.last_name)
          setLoading(false);
          saveToken(response.data.access_token);
          navigate(nextPage, {replace: true});
        }
        catch (e) {
          var status = e.response.status;
          if (status === 400){
              setError("wrong_credentials");
          }
          setLoading(false);
        }
      }

    const submitLogin = async e => {
        e.preventDefault();
        login();
    }
    return({
        loginUsername,
        setLoginUsername,
        loginPassword,
        setLoginPassword,
        submitLogin,
        nextPage,
        setNextPage,
    })
}

export { LoginFunctions };