// React
import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";


import { AppContext } from '../../../layout/AppContext';

// Layout
import { Layout } from "../../../layout/Layout";

// Ticket Page Boxes
import { TicketDescription } from "./TicketDescription";
import { TicketMessages } from "./TicketMessages";
import { TicketButtons } from "./TicketButtons";
import { TicketOptions } from "./TicketOptions";
import { TicketDetails } from "./TicketDetails";
import { TicketLasts } from "./TicketsLasts";
import { TicketSender } from "./TicketSender";
import { TicketLog } from "./TicketLog";

import { useNavigate } from "react-router-dom";

import moment from "moment";
import { LoadingComponent } from "../../Index/LoadingComponent";

function TicketPage(props) {
    
    // Primary
    const { id:idticket } = useParams();
    let navigate = useNavigate();

    // Context
    const { 
        getToken,
        API_ADDRESS,
        loading,
        setLoading,
        error,
        guestRedirect,
        } = React.useContext(AppContext);

    // Component States
    const [loadingTicket, setLoadingTicket] = React.useState(true);
    const [ticket, setTicket] = React.useState({});

    // Component Functions
    async function getTicket(){
        setLoading(true)
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                id: idticket
            }
        })
        .then(function (response) {
            setTicket(response.data);
            setLoadingTicket(false);
        })
        .catch(function (err) {
            setLoading(false);
        });
    }


    async function getMyProfile() {
        try {
            let response = await axios({
                method: 'get',
                url: API_ADDRESS + 'profiles/',
                headers: { Authorization: 'Token ' + getToken()}
            });
            if (response.status === 404) {
                navigate('/404');
            }
            if (response.status === 200) {
                if (response.data.client_profile) {
                    navigate('/404');
                } else {
                    
                }
            }
        }
        catch (e) {}
    }

    React.useEffect( () => {
        guestRedirect();
        getMyProfile();
        getTicket();
    }, [])

    
    return (
        <Layout>
            <div style={{
                width: '100%',
                height: '100%',
                zIndex: 10,
                backgroundColor: '#FFF',
                position: 'absolute',
                display: loadingTicket ? 'block' : 'none',
            }}
            >
                <LoadingComponent />
            </div>
                    <TicketSender
                            ticket={ticket}
                            getTicket={getTicket}
                            sender={ticket.sender}
                        />
                <div className="columns is">
                    <div className="column is-8">
                        <TicketDescription
                            ticket={ticket}
                        />
                        <TicketOptions 
                            ticket={ticket}
                            getTicket={getTicket}
                            forMobile={true}
                        />
                        <TicketMessages
                            ticket={ticket}
                            getTicket={getTicket}
                        />
                    </div>
                    <div className="column is-4">
                        {/* <TicketButtons /> */}
                        <TicketOptions 
                            ticket={ticket}
                            getTicket={getTicket}
                        />
                        <TicketDetails 
                            ticket={ticket}
                        />

                        <TicketLog
                            ticket={ticket}
                        />
                        {/* <TicketLasts /> */}
                    </div>
                </div>
        </Layout>
    )
}

export { TicketPage };