// React
import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";

// Extras
import moment from "moment/moment";

// Context
import { AppContext } from "../../layout/AppContext";

// Layout
import { Layout } from "../../layout/Layout";

// Icons
import { IonIcon } from "@ionic/react";
import { addCircle, arrowRedoOutline, ticketOutline} from 'ionicons/icons';

// Components
import { AllCanISeeTicketsClient } from "./Buttons/AllCanISeeTicketsClient";
import { CreatedByMeClient } from "./Buttons/CreatedByMeClient";

function ClientAreaPage(props) {
    moment.locale('es-mx')
    // Context
    const { setLoading, guestRedirect, MEDIA_ADDRESS, API_ADDRESS, getToken } = React.useContext(AppContext);

    const [tickets, setTickets] = React.useState([]);
    const [listWhat, setListWhat] = React.useState('created_by_me')

    async function getTickets(){
        setLoading(true)
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                mine: true,
                newer_first: true
            }
        })
        .then(function (response) {
            setTickets(response.data);
        })
        .catch(function (err) {
            setLoading(false);
        });
    }

    function momentDate(mydate) {
        const poronga = moment.utc(mydate).local().startOf('seconds').fromNow();
        return poronga;
    }

    const getListWhat = () => {
        const listWhatItem = localStorage.getItem('listWhat');
        if (!listWhatItem){
            localStorage.setItem('listWhat', 'created_by_me')
            document.getElementById('created_by_me').click();
            return ('');
        } else {
            setListWhat(listWhatItem)
            document.getElementById(listWhatItem).click();
            return ('');
        }
    }

    function saveListWhat(text){
        localStorage.setItem('listWhat', text);
        setListWhat(text);
    }

    React.useEffect( () => {
        guestRedirect();
        //getTickets();
        getListWhat();
    }, [])
    
    return(
        <Layout>
                <div className="box" style={{borderRadius: '0', border: '1px solid #eeeeee', marginBottom: '-1px', padding: '16px 8px 16px 20px'}}>
                <div className="field has-addons">
                    <Link className="button" to={'/clientarea/tickets/new'} style={{marginRight:'8px', backgroundColor: '#ff7143', color: '#FFF', border: 0}}>
                        <IonIcon icon={addCircle} />
                        <div style={{transform: 'translateY(2px)', marginLeft: '8px', fontWeight: 'bold'}}>Nuevo Ticket</div>
                    </Link>
                    
                    {/* <FilterBussiness />
                    <FilterStatus />
                    <FilterPriority />

                    <p className="control">
                        <Link className="button is-warning" to={'/tickets/new'} style={{marginRight: '8px', borderRadius:'4px'}}>
                            <IonIcon icon={refresh} />
                        </Link>
                    </p>
                    <p className="control">
                        <Link className="button is-link is-ok" to={'/tickets/new'} style={{borderRadius: '4px'}}>
                            <IonIcon icon={search} />
                        </Link>
                    </p> */}
                </div>
            </div>
            
            <div className="box" style={{border: '1px solid #eee', borderRadius: 0, marginBottom: '-1px'}}>
            <div className="field has-addons" id="ticket-buttons">                   
                    <div className="control" style={{marginRight: '16px', borderRight: '2px solid #444', paddingRight: '16px'}}>
                        <CreatedByMeClient
                            setTickets={setTickets}
                            saveListWhat={saveListWhat}
                        />
                    </div>
                    <div className="control" style={{marginRight: '16px', borderRight: '2px solid #444', paddingRight: '16px'}}>
                        <AllCanISeeTicketsClient
                            setTickets={setTickets}
                            saveListWhat={saveListWhat}
                        />
                    </div>
                </div>
            </div>

            <div className="box" style={{borderRadius: 0, border: '1px solid #EEE'}}>
                    {listWhat === 'created_by_me' && 
                        <div style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '12px'}}>
                            <div style={{float: 'left', marginRight: '8px', transform: 'translateY(3px)'}}>
                                <IonIcon icon={arrowRedoOutline} />
                            </div>
                            Mis tickets
                        </div>}
                    {listWhat === 'all_i_can_see' && <div style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '12px'}}>
                            <div style={{float: 'left', marginRight: '8px', transform: 'translateY(3px)'}}>
                                <IonIcon icon={ticketOutline} />
                            </div>
                            Tickets de la Empresa
                    </div>}
                <div className="table-container">
                    <table className="table is-bordered is-narrow is-hoverable is-fullwidth" style={{fontSize: '12px'}}>
                    <thead style={{backgroundColor: '#f5f5f5'}}>
                            <tr>
                                <th style={{width: '24px', textAlign: 'center'}}>P</th>
                                <th style={{width: '128px'}}>Categoria</th>
                                <th style={{width: '64px'}}><abbr title="Código">Código</abbr></th>
                                <th>Asunto</th>
                                <th style={{width: '180px'}}>Asignado a</th>
                                <th style={{width: '128px'}}>Estado</th>
                                <th style={{width: '98px', textAlign: 'center'}}>Creado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tickets && tickets.map(ticket =>
                                <tr key={ticket.id}>
                                    <td style={{textAlign: 'center'}}>
                                        {ticket.priority === 1 && <div className="priority-icon low"></div>}
                                        {ticket.priority === 2 && <div className="priority-icon mid"></div>}
                                        {ticket.priority === 3 && <div className="priority-icon high"></div>}
                                    </td>
                                    <td style={{fontWeight: 'bold'}}>
                                        {ticket.is_incident ?
                                        <div style={{color:'#ff3860'}}>{ticket.category.name}</div>:
                                        <div style={{color:'#48c774'}}>{ticket.category.name}</div>}
                                    </td>
                                    <td style={{fontWeight: 'bold'}}><Link to={'/clientarea/tickets/' + ticket.code}>{ticket.code}</Link></td>
                                    <td>
                                        {!ticket.open &&
                                            <div className="expiration-answer-icon locked" style={{display: 'inline-block', transform: 'scale(130%) translateY(2px)', marginRight: '4px'}} />
                                        }
                                        {ticket.subject}
                                    </td>
                                    <td><div className="avatar-mini">
                            {
                                ticket.owner && ticket.owner.avatar ?
                                <img src={MEDIA_ADDRESS + ticket.owner.avatar}/> :
                                <span>
                                    {ticket.owner && ticket.owner.name.slice(0,1) + ticket.owner.lastname.slice(0,1)}
                                </span>
                            }
                        </div>
                        <div
                            style={
                                ticket.owner.name !== "" ?(
                                ticket.owner.avatar
                                ?
                                {
                                    display: 'inline-grid',
                                    fontWeight: 'bold',
                                    transform: 'translateY(-7px)'
                                }
                                :
                                {
                                    display: 'inline-grid',
                                    fontWeight: 'bold',
                                }) : {
                                    display: 'inline-grid',
                                    fontWeight: 'bold',
                                    transform: 'translateY(4px)'
                                }

                            }
                        >
                            {ticket.owner && ticket.owner.name !== "" ? ticket.owner.name + " " + ticket.owner.lastname : 'Sin Asignar'}
                        </div>
                                        
                                    </td>
                                    <td>
                                        {ticket.status === 1 && <div className="priority-mark pending">Pendiente</div>}
                                        {ticket.status === 2 && <div className="priority-mark in-progress">En Progreso</div>}
                                        {ticket.status === 3 && <div className="priority-mark answered">Respondido</div>}
                                        {ticket.status === 4 && <div className="priority-mark waiting">En Espera</div>}
                                        {ticket.status === 5 && <div className="priority-mark solved">Resuelto</div>}
                                        {ticket.status === 6 && <div className="priority-mark finished">Finalizado</div>}
                                        {ticket.status === 7 && <div className="priority-mark closed">Cerrado</div>}
                                    </td>                                    
                                    <td>{momentDate(ticket.created_at)}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </Layout>
    )
}

export { ClientAreaPage };