// React
import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import moment from "moment";

// CSS
import 'bulma/css/bulma.min.css';
// import 'bulma-prefers-dark/css/bulma-prefers-dark.css';
import './style.css';

// // Components - Pages
// Index / Home
import { IndexPage } from "./pages/Index";

// Login Page
import { LoginPage } from "./pages/LoginPage";

// Tickets / HelpDesk
import { HelpDeskPage } from './pages/HelpDesk';
import { TicketPage } from './pages/HelpDesk/TicketPage';
import { TicketNewPage } from "./pages/HelpDesk/TicketNew";
import { TicketFinderPage } from "./pages/HelpDesk/TicketFinder";

// Tasks
import { TasksPage } from "./pages/Tasks";

// Knowledge Base
import { KnowledgeBasePage } from "./pages/KnowledgeBase";

// Inventory
import { InventoryPage } from "./pages/Inventory";

// Contacts
import { ContactsPage } from "./pages/Contacts";

// Access
import { AccessPage } from "./pages/Access";

// Services
import { ServicesPage } from "./pages/Services";

// Reports
import { ReportsPage } from "./pages/Reports";

// Administration
import { AdministrationPage } from "./pages/Administration";

// Client Area
import { ClientAreaPage } from "./pages/ClientArea";
import { ClientTicketNewPage } from "./pages/ClientArea/NewTicket";
import { ClientAreaTicketPage } from "./pages/ClientArea/TicketPage";

// 404
import { Page404 } from "./pages/404";

moment.locale('es-mx')

function App() {
  return (
    <div className="root">
      <BrowserRouter>
        <Routes>
          {/* Login Page */}
          <Route path="/login" element={<LoginPage />} />

          {/* Initial / Home */}
          {/* <Route path="/" element={<IndexPage />} /> */}
          <Route path="/" element={<IndexPage />} />

          {/* Tickets */}
          <Route path="/tickets" element={<HelpDeskPage />} />
          <Route path="/tickets/new" element={<TicketNewPage />} />
          <Route path="/tickets/:id" element={<TicketPage />} />
          <Route path="/tickets/finder" element={<TicketFinderPage />} />

          {/* Tasks */}
          {/* <Route path="/tasks" element={<TasksPage />} /> */}

          {/* Knowledge Base */}
          {/* <Route path="/knowledgebase" element={<KnowledgeBasePage />} /> */}

          {/* Inventory */}
          {/* <Route path="/inventory" element={<InventoryPage />} /> */}

          {/* Contacts */}
          {/* <Route path="/contacts" element={<ContactsPage />} /> */}

          {/* Access */}
          {/* <Route path="/access" element={<AccessPage />} /> */}

          {/* Services */}
          {/* <Route path="/services" element={<ServicesPage />} /> */}

          {/* Reports */}
          {/* <Route path="/reports" element={<ReportsPage />} /> */}

          {/* Administration */}
          {/* <Route path="/administration" element={<AdministrationPage />} /> */}

          {/* Client Area */}
          <Route path="/clientarea" element={<ClientAreaPage />} />
          <Route path="/clientarea/tickets/new" element={<ClientTicketNewPage />} />
          <Route path="/clientarea/tickets/:code" element={<ClientAreaTicketPage />} />


          {/* 404 Page */}
          <Route path="/404" element={<Page404 />} />
          <Route path="/*" element={<Page404 />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
