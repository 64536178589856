// React
import React from "react";
import axios from "axios";
import fileDownload from "js-file-download";


import { AppContext } from "../../../../layout/AppContext";

import { ContactBox } from "./ContactBox";

function TicketDescription(props){

    // Context
    const { 
        getToken,
        API_ADDRESS,
        loading,
        setLoading,
        error,
        guestRedirect,
        momentDate,
        MEDIA_ADDRESS
        } = React.useContext(AppContext);

    
    async function getFile(id, filename){
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/download/' + id + '/',
            headers: { Authorization: 'Token ' + getToken()},
            responseType: 'blob'
        })
        .then(function (response) {
            fileDownload(response.data, filename)
        })
        .catch(function (err) {
            
        });
    }
    
    return(
        <React.Fragment>
            <div 
                className="box" 
                // style={{marginBottom: '-1px',borderRadius:'0', border: '1px solid #eee', paddingBottom: '4px', paddingTop: '10px'}}
                style={{
                    border: '2px solid #5a6672',
                    borderRadius: '8px',
                    marginBottom: '8px',
                    padding: '12px 12px 8px 12px',
                    whiteSpace: 'pre-wrap'
                }}
            >
                <div>
                {props.ticket.is_incident ?
                    <React.Fragment>
                    <div 
                        style={{
                            width:'16px',
                            height: '16px', 
                            backgroundColor:'#ff3860', 
                            borderRadius: '8px', 
                            boxShadow: '0 0 2px #212121', 
                            display: 'inline-block', 
                            transform: 'translateY(3px)'
                        }} />
                    </React.Fragment>
                    :
                    <React.Fragment>
                    <div 
                        style={{
                            width:'16px',
                            height: '16px', 
                            backgroundColor:'#48c774', 
                            borderRadius: '8px', 
                            boxShadow: '0 0 2px #212121', 
                            display: 'inline-block', 
                            transform: 'translateY(3px)'
                        }}/>
                    </React.Fragment>
                    }
                    <span className="is-size-7" style={{fontFamily: 'MadeTommy-SoftBold', color: '#171e28'}}>&nbsp;&nbsp;Ticket {props.ticket.code}</span>
                    <span className="is-size-7"style={{color: '#687a8e', fontWeight: 'bold'}}>&nbsp; (Creado {momentDate(props.ticket.created_at)})</span>
                    <br />
                    <span className="title is-5">{props.ticket.subject}</span>
                    {!props.ticket.open &&
                        <span className="title is-7" style={{color: 'red'}}>
                            <div className="expiration-answer-icon locked" style={{display: 'inline-block', transform: 'scale(130%) translateY(2px)', marginLeft: '4px'}} />
                            &nbsp;(Cerrado)&nbsp;
                        </span>
                    }
                </div>
                <span className="is-size-7" style={{fontWeight: 'bold',fontFamily: 'MadeTommy-SoftBold', }}>Descripción</span>
                <br />

                {props.ticket.message}
                
                {props.ticket.ticket_attach && props.ticket.ticket_attach.length > 0 &&
                <React.Fragment>
                <br />
                <span className="is-size-7" style={{fontWeight: 'bold',fontFamily: 'MadeTommy-SoftBold', }}>Archivos adjuntos</span>
                <br />
                {props.ticket.ticket_attach &&
                props.ticket.ticket_attach.map(att => 
                <React.Fragment key={att.id}>
                    {/* <a href={MEDIA_ADDRESS + att.file}> */}
                    <div className='attach_link' onClick={() => getFile(att.id, att.file_name)}>
                        {att.file_name}
                    </div>
                    <br />
                </React.Fragment>
                )}
                </React.Fragment>
                }
            </div>
            {!props.ticket.open &&
            <div 
                className="box" 
                // style={{marginBottom: '-1px',borderRadius:'0', border: '1px solid #eee', paddingBottom: '4px', paddingTop: '10px'}}
                style={{
                    border: '2px solid #5a6672',
                    borderRadius: '8px',
                    marginBottom: '0',
                    padding: '12px 12px 8px 12px',
                    whiteSpace: 'pre-wrap',
                    fontSize: '14px'
                }}
            >
                <span className="is-size-7" style={{fontWeight: 'bold',fontFamily: 'MadeTommy-SoftBold', }}>Comentarios Finales (ticket cerrado)</span>
                <br />
                {props.ticket.solved_comment ? props.ticket.solved_comment : <i style={{color: '#888'}}>No hay comentarios finales</i>}
            </div>
            }
        </React.Fragment>
    )
}

export { TicketDescription };