// React
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Extras
import Countdown from "react-countdown";

// Context
import { AppContext } from "../../../../layout/AppContext";

function TicketOptions(props){

    // Primary
    const { loading, setLoading, countdownRenderer, guestRedirect, MEDIA_ADDRESS, API_ADDRESS, getToken } = React.useContext(AppContext);

    const navigate = useNavigate();

    return(
        <div
        style={{
            border: '2px solid #5a6672',
            borderRadius: '8px',
            marginBottom: '8px',
            padding: '12px 12px 5px 12px'
        }}
        className={"box " + props.forMobile && props.forMobile === true ? 'is-hidden-tablet' : 'is-hidden-mobile'}
        >
            <h6 className="title is-6 has-text-centered" style={{marginBottom: '8px'}}>Opciones del ticket</h6>
            <div>
                Tipo:&nbsp;
                <strong>
                {props.ticket.is_incident ?
                    <React.Fragment>
                    <div 
                        style={{
                            width:'16px',
                            height: '16px', 
                            backgroundColor:'#ff3860', 
                            borderRadius: '8px', 
                            boxShadow: '0 0 2px #212121', 
                            display: 'inline-block', 
                            transform: 'translateY(2px)'
                        }} />
                        &nbsp;Incidente
                    </React.Fragment>
                    :
                    <React.Fragment>
                    <div 
                        style={{
                            width:'16px',
                            height: '16px', 
                            backgroundColor:'#48c774', 
                            borderRadius: '8px', 
                            boxShadow: '0 0 2px #212121', 
                            display: 'inline-block', 
                            transform: 'translateY(2px)'
                        }}/>
                        &nbsp;Requerimiento
                    </React.Fragment>
                    }
                    
                </strong>
            </div>
            <div>
                Vencimiento:&nbsp;
                {props.ticket.open ?
                        (
                            props.ticket.expiration &&
                            <Countdown
                                date={props.ticket.expiration}
                                renderer={countdownRenderer}
                            />
                        ) :
                        (
                            props.ticket.solved_intime ?
                            <React.Fragment>
                                <div className="expiration-answer-icon intime" />
                            </React.Fragment> :
                            <React.Fragment>
                                <div className="expiration-answer-icon not-intime" />
                            </React.Fragment>
                            
                        )
                    }
            </div>
            <div>
                Estado:&nbsp;
                <strong>
                    {props.ticket && props.ticket.ticket_status && props.ticket.ticket_status.public_name}    
                </strong>
            </div>
            <div>
                Categoria:&nbsp;
                <strong>
                    {props.ticket.category && props.ticket.category.name}
                </strong>
            </div>
            {props.ticket && props.ticket.priority === 3 &&
            <div>
                Prioridad:&nbsp;
                <strong>
                    {props.ticket.priority === 1 && 'Baja'}
                    {props.ticket.priority === 2 && 'Media'}
                    {props.ticket.priority === 3 && 'Alta'}
                </strong>
            </div>
            }
            <div>
                <div style={{display: 'inline', float: 'left'}}>Asignado a:&nbsp;</div>
                <strong>
                    <div className="avatar-mini-enterprise">
                        {props.ticket && props.ticket.owner && props.ticket.owner.name && props.ticket.owner.user !== null &&
                            (props.ticket.owner.avatar  ?
                            <img src={MEDIA_ADDRESS + props.ticket.owner.avatar}/>
                        :<span>{props.ticket.owner.name.slice(0,2)}</span>
                        )}
                    </div>
                    <span>
                        {props.ticket && props.ticket.owner && props.ticket.owner.user !== null  ? props.ticket.owner.name + " " + props.ticket.owner.lastname : "Sin asignar"}
                    </span>
                </strong>
            </div>
        </div>         
    )
}

export { TicketOptions };