// React
import React from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

// Context
import { AppContext } from '../../../layout/AppContext';

// Layout
import { Layout } from "../../../layout/Layout";

// Ticket Page Boxes
import { TicketDescription } from "./TicketDescription";
import { TicketMessages } from "./TicketMessages";
import { TicketButtons } from "./TicketButtons";
import { TicketOptions } from "./TicketOptions";
import { TicketDetails } from "./TicketDetails";
import { TicketLasts } from "./TicketsLasts";
import { TicketSender } from "./TicketSender";
import moment from "moment";

function ClientAreaTicketPage(props) {
    
    // Primary
    const { code:codeticket } = useParams();

    let navigate = useNavigate();
    // Context
    const { 
        getToken,
        API_ADDRESS,
        loading,
        setLoading,
        error,
        guestRedirect,
        } = React.useContext(AppContext);

    // Component States
    const [loadingTicket, setLoadingTicket] = React.useState(false);
    const [ticket, setTicket] = React.useState({})
    
    // Component Functions
    async function getTicket(){
        setLoading(true)
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                code: codeticket
            }
        })
        .then(function (response) {
            setTicket(response.data);
        })
        .catch(function (err) {
            setLoading(false);
            
            if (err.response.status === 404) {
                navigate('/404')
            }
        });
    }

    React.useEffect( () => {
        guestRedirect();
        getTicket();
    }, [])

    return (
        <Layout>
            <TicketSender
                ticket={ticket}
                getTicket={getTicket}
                sender={ticket.sender}
            />
            <div className="columns is">
                <div className="column is-8">
                    <TicketDescription
                        ticket={ticket}
                    />
                    <TicketOptions
                        ticket={ticket}
                        getTicket={getTicket}
                        forMobile={true}
                    />
                    <TicketMessages
                        ticket={ticket}
                    />
                </div>
                <div className="column is-4">
                    {/* <TicketButtons /> */}
                    <TicketOptions
                        ticket={ticket}
                        getTicket={getTicket}
                    />
                    <TicketDetails 
                        ticket={ticket}
                    />
                    {/* <TicketLasts /> */}
                </div>
            </div>
        </Layout>
    )
}

export { ClientAreaTicketPage };