// React
import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Extras
import moment from "moment/moment";

// Context
import { AppContext } from "../AppContext";

// Icons
import { IonIcon } from "@ionic/react";
import { mail, logOut, personCircle } from 'ionicons/icons'
import logo2 from './logo3.png';
import notificationIcon from './notificationIcon.svg';

function NavBar(props) {
    // Primary
    let navigate = useNavigate();
    moment.locale('es-mx')

    // AppContext
    const { deleteToken, API_ADDRESS, getToken, myProfile, setMyProfile, MEDIA_ADDRESS } = React.useContext(AppContext);

    // Functions
    function logout() {
        deleteToken();
        window.location.href = '/';
    }

    const [notificationBoxOpen, setNotificationBoxOpen] = React.useState(false);
    const [profileBoxOpen, setProfileBoxOpen] = React.useState(false);
    
    async function getMyProfile() {
        try {
            let response = await axios({
                method: 'get',
                url: API_ADDRESS + 'profiles/',
                headers: { Authorization: 'Token ' + getToken()}
            });
            if (response.status === 404) {
                navigate('/404');
            }
            if (response.status === 200) {
                setMyProfile(response.data);
            }
        }
        catch (e) {}
    }

    const [notifications, setNotifications] = React.useState([]);
    const [notificationsCount, setNotificationsCount] = React.useState(0);

    async function getMyNotifications() {
        try {
            let response = await axios({
                method: 'get',
                url: API_ADDRESS + 'profiles/notifications/',
                headers: { Authorization: 'Token ' + getToken()}
            });
            if (response.status === 200) {
                setNotifications(response.data);
            }
        }
        catch (e) {}
    }

    async function getMyNotificationsCount() {
        try {
            let response = await axios({
                method: 'get',
                url: API_ADDRESS + 'profiles/notifications/count/',
                headers: { Authorization: 'Token ' + getToken()}
            });
            if (response.status === 200) {
                setNotificationsCount(response.data.count)
                if (response.data.count > 0){
                    document.title = "(" + (response.data.count) + ") Soporte Técnico - MS it"
                }
            }
        }
        catch (e) {}
    }

    async function markReadNotification(notification_id) {
        await axios({
                method: 'put',
                url: API_ADDRESS + 'profiles/notifications/',
                headers: { Authorization: 'Token ' + getToken()},
                data: {
                    id: notification_id
                }
            }).then(function (response){
                console.log(response)
            }).catch(function(err){
                console.log(err)
            })
    }
    
    function momentDate(mydate) {
        const poronga = moment.utc(mydate).local().startOf('seconds').fromNow();
        return poronga;
    }

    React.useEffect(() => {
        getMyProfile();
        getMyNotifications();
        getMyNotificationsCount();
    }, []);

    return(
        <React.Fragment>
            <nav className="navbar" role="navigation" aria-label="main navigation" style={{boxShadow: '0 2px 2px #f5f5f5', backgroundColor: '#171e28'}}>
                
                <div className="profile-button" onClick={() => setProfileBoxOpen(!profileBoxOpen)} tabIndex={0}>
                    {
                        myProfile && myProfile.avatar ?
                        <img src={MEDIA_ADDRESS + myProfile.avatar}/> :
                        <span>{myProfile.name && myProfile.name.slice(0,1) + myProfile.lastname.slice(0,1)}</span>
                    }
                </div>
                <div className={profileBoxOpen ? "profile-box" : "profile-box is-hidden"}>
                    <ul>
                        <li>
                            Nombre: <b>{myProfile && myProfile.name + ' ' + myProfile.lastname}</b> <br />
                            Empresa: <span className="has-text-info"><b>{myProfile && myProfile.enterprise && myProfile.enterprise.name}</b></span> <br />
                            Cargo: <b>{myProfile && myProfile.bussiness_function}</b>
                        </li>
                        <li>
                        <div style={{float: 'left', marginRight: '4px'}}><IonIcon icon={mail}  size="small"/></div>
                        {myProfile && myProfile.email}
                        </li>
                        <li className="hovereable">
                            <div style={{float: 'left', marginRight: '4px'}}><IonIcon icon={personCircle}  size="small"/></div>
                            Ver Perfil
                        </li>
                        <li style={{border: 'none'}} className="hovereable" onClick={() => logout()}>
                            <div style={{float: 'left', marginRight: '3px', marginLeft: '1px'}}>
                                <IonIcon icon={logOut} size="small" />
                            </div>Cerrar Sesion
                        </li>
                    </ul>    
                </div>
                <div className="notification-button" onClick={() => setNotificationBoxOpen(!notificationBoxOpen)} tabIndex={0}>
                    <img src={notificationIcon} />
                    {notificationsCount > 0 && notificationsCount < 10 && <div className="counter nine-plus">{notificationsCount}</div>}
                    {notificationsCount > 9 && <div className="counter nine-plus">9+</div>}
                </div>
                <div className={notificationBoxOpen ? "notification-box" : "notification-box is-hidden"}>
                    <ul>
                        <li>
                            <b>Notificaciones</b>
                        </li>
                        {notifications.length === 0 && 
                        <li>
                            <i>No hay notificaciones</i>
                        </li>
                        }
                        {notifications && Array.isArray(notifications) && notifications.map(notification =>
                            <a 
                                key={notification.id} 
                                // href={notification.link} 
                                style={{color: '#212121'}} 
                                onClick={() => {
                                    markReadNotification(notification.id);
                                    setTimeout( () =>
                                        window.location = notification.link,
                                    10
                                    )
                                }}
                            >
                                <li
                                    style={
                                        !notification.read ?
                                        {backgroundColor: '#fff9c4'}:
                                        {backgroundColor: 'transparent'}
                                    }
                                >
                                    <div className={'notification-icon ' + notification.type}></div>
                                    {notification.text}
                                    <div style={{width: '100%', textAlign: 'right'}}>
                                    <i>{momentDate(notification.created_at)}</i>
                                    </div>
                                </li>
                            </a>
                        )}
                        <li style={{border: 'none'}} className="hovereable" onClick={() => logout()}>
                            <div style={{float: 'left', marginRight: '3px', marginLeft: '1px'}}>
                                <IonIcon icon={logOut} size="small" />
                            </div>Ver todas
                        </li>
                    </ul>    
                </div>
                <div className="navbar-brand">
                    <a className="navbar-item" href="/">
                        <img src={logo2} width="58" height="40" />
                        <span style={{fontWeight: 'bold', color: '#FFFFFF'}}>&nbsp;&nbsp;Soporte Técnico</span>
                    </a>
                </div>

            </nav>
        </React.Fragment>
    );
}

function ColumnMenu(props) {
    return(
        <div className="column is-2-desktop is-1-tablet">
            <div className="block">
                <aside className="menu">
                    <p className="menu-label">
                        General
                    </p>
                    <ul className="menu-list">
                        <li><Link to={'/'}>Inicio</Link></li>
                        <li><Link to={'/tasks'}>Tareas</Link></li>
                        <li><Link to={'/tickets'}>Tickets</Link></li>
                        <li><Link to={'/knowledgebase'}>Base de Conocimiento</Link></li>
                        <li><Link to={'/inventory'}>Inventario</Link></li>
                        <li><Link to={'/contacts'}>Contactos</Link></li>
                        <li><Link to={'/access'}>Accesos</Link></li>
                        <li><Link to={'/services'}>Servicios</Link></li>
                    </ul>
                    <p className="menu-label">
                        Administracion
                    </p>
                    <ul className="menu-list">
                        <li><Link to={'/reports'}>Reportes</Link></li>
                        <li><Link to={'/administration'}>Administration</Link></li>
                    </ul>
                </aside>
            </div>
        </div>
    );
}

function ColumnSpaceWork(props) {
    return(
        
        <div className="column">
                    <div className="block">
                            {props.children}
                    </div>
                </div>
    );
}

export { NavBar, ColumnMenu, ColumnSpaceWork };