import React from "react";
import { IonIcon } from "@ionic/react";
import { pencil, document, alertCircle, send, hammer, copy, layers, person, people, lockClosed  } from 'ionicons/icons'

import axios from "axios";

import { AppContext } from "../../../../layout/AppContext";

function TicketLog(props){

    const [ticketLogs, setTicketLogs] = React.useState([]);

    const { API_ADDRESS, getToken, momentDate } = React.useContext(AppContext);

    async function getTicketLogs(idticket){
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/log/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                ticket: idticket
            }
        })
        .then(function (response) {
            setTicketLogs(response.data)
        })
        .catch(function (err) {

        });
    }

    React.useEffect(() => {
        if (props.ticket != {}) {
            getTicketLogs(props.ticket.id);
        }
    }, [props.ticket])

    return(
        <div className="box" 
        style={{
            border: '2px solid #5a6672',
            borderRadius: '8px',
            marginBottom: '8px',
            padding: '12px 12px 5px 12px'
        }}
        >
            <h6 className="title is-6 has-text-centered" style={{marginBottom: '8px'}}>Registro</h6>

            {ticketLogs.map(log =>
                <p 
                    style={{
                            borderBottom: '1px solid #eee', 
                            marginTop: '4px', 
                            fontSize: '14px'
                           }}
                    key={log.id}
                >
                    <IonIcon
                        icon={
                              log.icon && 
                              log.icon === 'pencil' ? pencil :
                              log.icon === 'alert-circle' ? alertCircle :
                              log.icon === 'document' ? document : 
                              log.icon === 'send' ? send :
                              log.icon === 'hammer' ? hammer :
                              log.icon === 'copy' ? copy :
                              log.icon === 'layers' ? layers :
                              log.icon === 'person' ? person :
                              log.icon === 'people' ? people :
                              log.icon === 'lock-closed' ? lockClosed :
                              pencil
                             }
                        size="12px" 
                        style={{
                                transform: 'translateY(2px)',
                                marginRight: '4px'
                               }}
                    />
                    <strong>
                        {log.action_by.name + " " + log.action_by.lastname}
                    </strong>
                    &nbsp;
                    {log.action}
                    &nbsp;
                    <span style={{fontSize: '10px', color: '#777'}}>
                        {momentDate(log.created_at)}
                    </span>
                </p>
            )}
        </div>
    )
}

export { TicketLog };