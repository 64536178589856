// React
import React from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

// Extras
import { useDropzone } from "react-dropzone";
import fileDownload from "js-file-download";

// Context
import { AppContext } from "../../../../layout/AppContext";

// Icons
import { IonIcon } from "@ionic/react";
import { leaf, send, checkboxOutline, closeOutline, star } from 'ionicons/icons'

// Components
import { ContactBox } from "../TicketDescription/ContactBox";

function TicketMessages(props){

    const navigate = useNavigate();
    // Context
    const { 
        getToken,
        API_ADDRESS,
        momentDate,
        MEDIA_ADDRESS,
        notify,
        } = React.useContext(AppContext);

    // Component States
    const [messages, setMessages] = React.useState([]);
    const [messageInput, setMessageInput] = React.useState('');
    const [listOfFiles, setListOfFiles] = React.useState([]);

    const [messageBoxOpen, setMessageBoxOpen] = React.useState(false);
    const [myProfile, setMyProfile] = React.useState({});

    // Component Functions
    async function getMessages(idticket){
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/messages/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                idticket: idticket
            }
        })
        .then(function (response) {
            setMessages(response.data)
        })
        .catch(function (err) {
        });
    }

    async function postMessage(){
        await axios({
            method: 'post',
            url: API_ADDRESS + 'tickets/messages/',
            headers: { Authorization: 'Token ' + getToken()},
            data: {
                ticket: props.ticket.id,
                message: messageInput,
                attach: listOfFiles,
                mentions: null,
                type: 'message',
            }
        })
        .then(function (response) {
            getMessages(props.ticket.id);
            setListOfFiles([]);
            document.getElementById("message-input").value = '';
            setMessageInput('');
            setUploadedFiles([]);
            setMessageBoxOpen(false);
            notify('Mensaje enviado', 'success');
        })
        .catch(function (err) {
            if (err.response.status === 400) {
                notify('Debes escribir un mensaje', 'warn');
            } else {
                notify('Algo no funcionó', 'error');
            }
        });
    }

    const regex = /@\[(.*)\]/g;

    /// Regex explain
    // ---- start and end with /-- ex: /expresion/g; (g from global)
    // @(alone simbol)
    // [ with \ previous 
    // (.*) anything
    // ] with \ previous 
    // ----usage---
    /// string.replace(regex, `$1`)

    async function getMyProfile() {
        try {
            let response = await axios({
                method: 'get',
                url: API_ADDRESS + 'profiles/',
                headers: { Authorization: 'Token ' + getToken()}
            });
            if (response.status === 404) {
                navigate('/404');
            }
            if (response.status === 200) {
                setMyProfile(response.data);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const [isUploadingFile, setIsUploadingFile] = React.useState(false)

    async function postTicketAttach(file, filename){
        const formData = new FormData();
        formData.append("file", file)
        formData.append("filename", filename)
        setIsUploadingFile(true);
        await axios({
            method: 'post',
            url: API_ADDRESS + 'tickets/ticket_attach/',
            headers: {
                'Authorization': 'Token ' + getToken(),
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        }) .then(function (response) {
            setIsUploadingFile(false);
            setListOfFiles(current => [...current, response.data.id]);
        })
        .catch(function (err) {
            setIsUploadingFile(false);
        });
    }
    
    const [uploadedFiles, setUploadedFiles] = React.useState([]);

    const handleDrop = (acceptedFiles) => {
        if (uploadedFiles.length === 0) {
            setUploadedFiles(acceptedFiles)
        } else {
            setUploadedFiles(uploadedFiles.concat(acceptedFiles));
        }
        acceptedFiles.map(file => postTicketAttach(file, file.name));
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'text/plain': ['.txt'],
            'application/pdf': ['.pdf'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/zip': ['.zip'],
            'application/vnd.rar': ['.rar'],
        },
        onDrop: handleDrop
    });

    async function getFile(id, filename){
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/download/' + id + '/',
            headers: { Authorization: 'Token ' + getToken()},
            responseType: 'blob'
        })
        .then(function (response) {
            fileDownload(response.data, filename)
        })
        .catch(function (err) {
        });
    }

    async function authorizeMessage(id_message, what_do){
        await axios({
            method: 'post',
            url: API_ADDRESS + 'tickets/messages/authorize/',
            headers: { Authorization: 'Token ' + getToken()},
            data: {
                message: id_message,
                what_do: what_do,
            }
        })
        .then(function (response) {
            if (response.data === 'accepted') {
                notify('Has autorizado el servicio', 'success')
            }
            if (response.data === 'rejected') {
                notify('Has rechazado el servicio', 'warn')
            }
            getMessages(props.ticket.id);            
        })
        .catch(function (err) {
            if (err.response.data === 'unauthorized'){
                notify('No tienes permiso para autorizar', 'error');
            }
        })
    }

    const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

    React.useEffect(() => {
        if (props.ticket != {}) {
            getMessages(props.ticket.id);
        }
        getMyProfile();
    }, [props.ticket]);

    React.useLayoutEffect(() => {
        document.getElementById('message-input').addEventListener('paste', async function(e) {
            const data = await navigator.clipboard.read()
            for (const item of data) {
                for (const type of item.types) {
                    if (type === 'image/png'){
                        var blob = await item.getType('image/png');
                        var file = new File([blob], genRanHex(12)+ ".png", {type: 'image/png'})
                        postTicketAttach(file, genRanHex(12)+ ".png")
                        setUploadedFiles(current => [...current, file])
                    }
                }
            }
        
    })
    }, [])

    return(
        <React.Fragment>
            <div style={{height: '8px'}}></div>
            <div
                className="box"
                style={{
                    border: '2px solid #5a6672',
                    borderRadius: '8px',
                    marginBottom: '8px',
                    padding: '12px 12px 5px 12px'
                }}
            >
                <span className="title is-5">Mensajes</span>
                {props.ticket && props.ticket.open &&
                <div 
                    className="box"
                    style={{
                        padding: '8px',
                        border: '1px solid #BBB',
                        borderRadius: 0,
                        fontWeight: 'bold',
                        margin: '8px 0 8px 0',
                    }}
                >
                    <div className="austero-menu-item" onClick={() => setMessageBoxOpen(!messageBoxOpen)}>Responder Ticket</div>
                </div>
            }
                <div 
                    style={{
                        borderBottom: '1px solid #afb7c1',
                        margin:'12px 0 8px 0'
                    }}>

                    </div>
                
                {props.ticket && 
                    <div 
                        className="box" 
                        style={{
                            marginTop: '0px', 
                            padding: '4px 8px 8px 8px', 
                            borderRadius: 0,
                            display: messageBoxOpen ? 'block' : 'none',
                            transition: 'height .3s ease',
                        }}
                        id="NewMessageBox"
                    >
                        <span style={{fontSize: '14px'}}>Nueva respuesta al ticket</span>
                        <div className="close_new_message" onClick={() => setMessageBoxOpen(!messageBoxOpen)}>Cerrar</div>
                        <textarea 
                            className="textarea" 
                            placeholder="Escribe el mensaje aquí"
                            onChange={e => {setMessageInput(e.target.value)}}
                            id="message-input"
                            style={{marginBottom: '4px', marginTop: '2px'}}
                        >
                        </textarea>
                        <div className="columns">
                            <div className="column is-narrow">
                                <div {...getRootProps()}
                                    className="upload_attach"
                                >
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    <p>Agrega archivos adjuntos aquí</p>
                                }
                            </div>
                            <ul style={{marginTop: '8px'}}>
                                {uploadedFiles.map((file, index) => (
                                    <React.Fragment key={index}>
                                        <li className="attach_link_new" key={file.name}>{file.name}</li>
                                        <br />
                                    </React.Fragment>
                                ))}
                            </ul>    
                        </div>
                        
                        <div className="column" style={{float: 'right'}}>
                            <button className={isUploadingFile ? "button is-info is-small is-loading is-disabled" : "button is-info is-small"} onClick={() => postMessage()} style={{float: 'right'}}>
                                <span className="icon is-small">
                                    <IonIcon icon={send} />
                                </span>
                                <span>Enviar mensaje</span>
                            </button>
                        </div>

                    </div>
                    </div>
                }

                {messages.map(message =>
                    <div 
                        className={
                                message.type && message.type === "message" ? "box bgMessage" :
                                message.type === "internal" ? "box bgInternal" :
                                message.type === "authorization" ? "box bgAuthorization" : "box bgMessage"
                                }          
                        key={message.id}
                        style={
                            {
                            borderRadius: 0,
                            marginBottom: '8px',
                            whiteSpace: "pre-wrap",
                            padding: 0
                        }}
                        >
                    <div>
                    <div className="avatar-mini">
                        {
                            message.created_by && message.created_by.avatar ?
                            <img src={MEDIA_ADDRESS + message.created_by.avatar}/> :
                            <span>
                                {message.created_by && message.created_by.name.slice(0,1) + message.created_by.lastname.slice(0,1)}
                            </span>
                        }
                    </div>
                    <div
                        style={
                            message.created_by.avatar
                            ?
                            {
                                display: 'inline-grid',
                                fontWeight: 'bold',
                                transform: 'translateY(-7px)'
                            }
                            :
                            {
                                display: 'inline-grid',
                                fontWeight: 'bold',
                            }
                        }
                    >
                        {message.created_by && message.created_by.name + " " + message.created_by.lastname}
                    </div>
                    
                    <div 
                    className="is-size-7 ml-2"
                    style={
                        message.created_by.avatar
                        ?
                        {
                            display: 'inline-grid',
                            transform: 'translateY(-7px)'
                        }
                        :
                        {
                            display: 'inline-grid',
                            fontWeight: 'bold',
                        }
                    }
                >
                    ({momentDate(message.created_at)})
                </div>
                </div>

                {message.type === 'internal' || message.type === 'authorization' &&
                        <React.Fragment>
                            <IonIcon icon={star} style={{fontSize: 12}}/>
                            <span style={{fontSize: 12}}>
                                {message.type === 'internal' && " Nota interna"}
                                {message.type === 'authorization' && " Autorización"}
                            </span>
                        </React.Fragment>
                    }   
                
                {/* <ContactBox sender={message.created_by} isMessage={true}/> */}
                <div style={{paddingBottom: '12px' ,fontSize: '15px', borderBottom: '1px solid #afb7c1'}}>
                    
                    {message.message.replace(regex, `$1`)}
                    
                    {message.ticket_attach.length > 0 && 
                        <React.Fragment>
                            <br />
                            <span className="is-size-7" style={{fontWeight: 'bold',fontFamily: 'MadeTommy-SoftBold', }}>Archivos adjuntos</span>
                            <br />
                            {message.ticket_attach &&
                            message.ticket_attach.map(att => 
                                <React.Fragment key={att.id}>
                                    {/* <a href={MEDIA_ADDRESS + att.file}> */}
                                    <div className='attach_link_message' onClick={() => getFile(att.id, att.file_name)}>
                                        {att.file_name}
                                    </div>
                                    <br />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    }
                    </div>
                    {
                        message.type === 'authorization' &&
                        <React.Fragment>
                            {message.authorized_at === null ?
                            <div style={{borderTop: '1px solid #afb7c1', paddingTop: '8px'}}>
                            <button className="button is-success is-small is-responsive" onClick={() => authorizeMessage(message.id, 'accepted')}>
                                Autorizar
                            </button>
                            <button style={{marginLeft: '8px'}} className="button is-danger is-small is-responsive" onClick={() => authorizeMessage(message.id, 'rejected')}>
                                Rechazar
                            </button>
                        </div> : 
                        <div style={{fontSize: '12px', fontWeight: 'bold', borderTop: '1px solid #afb7c1', paddingTop: '8px'}}>
                            {message.authorized && message.authorized === 'accepted' && <IonIcon icon={checkboxOutline} style={{fontSize: 14, transform: 'translateY(2px)', color: 'green'}}/>}
                            {message.authorized && message.authorized === 'rejected' && <IonIcon icon={closeOutline} style={{fontSize: 14, transform: 'translateY(2px)', color: 'red'}}/>}
                            {message.authorized && message.authorized === 'accepted' && ' Autorizado por: ' }
                            {message.authorized && message.authorized === 'rejected' && ' Rechazado por: ' }
                            {message.authorized_by.name + " " + message.authorized_by.lastname}
                            <br />
                            {momentDate(message.authorized_at)}
                        </div>
                            }
                        </React.Fragment>
                    }
                </div>
                
            )}
            </div>      
        </React.Fragment>
    )
}

export { TicketMessages };