import React from "react";
import axios from "axios";

import { Mention, MentionsInput } from "react-mentions";

import { IonIcon } from "@ionic/react";
import { send } from 'ionicons/icons'

import { AppContext } from "../../../../../layout/AppContext";
import { useDropzone } from "react-dropzone";

import mentionStyles from "../../../../../mentionStyles";
import mentionsInputStyle from "../../../../../mentionInputStyles";

import './NewMessage.css';

function NewMessage(props){

    // Context
    const { 
        getToken,
        API_ADDRESS,
        loading,
        setLoading,
        error,
        momentDate,
        MEDIA_ADDRESS,
        notify,
    } = React.useContext(AppContext);

    const [messageInput, setMessageInput] = React.useState('');
    const [listOfFiles, setListOfFiles] = React.useState([]);

    const [listOfMentions, setListOfMentions] = React.useState([]);

    const [uploadedFiles, setUploadedFiles] = React.useState([]);

    const handleDrop = (acceptedFiles) => {
        if (uploadedFiles.length === 0) {
            setUploadedFiles(acceptedFiles)
        } else {
            setUploadedFiles(uploadedFiles.concat(acceptedFiles));
        }
        acceptedFiles.map(file => postTicketAttach(file, file.name));
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'text/plain': ['.txt'],
            'application/pdf': ['.pdf'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/zip': ['.zip'],
            'application/vnd.rar': ['.rar'],
        },
        onDrop: handleDrop
    });

    const [isUploadingFile, setIsUploadingFile] = React.useState(false)

    async function postTicketAttach(file, filename){
        // const file = files
        const formData = new FormData();
        formData.append("file", file)
        formData.append("filename", filename)
        setIsUploadingFile(true);
        await axios({
            method: 'post',
            url: API_ADDRESS + 'tickets/ticket_attach/',
            headers: {
                'Authorization': 'Token ' + getToken(),
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        }) .then(function (response) {
            setIsUploadingFile(false);
            setListOfFiles(current => [...current, response.data.id])
        })
        .catch(function (err) {
            setIsUploadingFile(false);
        });
    }

    

    async function postMessage(){
        await axios({
            method: 'post',
            url: API_ADDRESS + 'tickets/messages/',
            headers: { Authorization: 'Token ' + getToken()},
            data: {
                ticket: props.ticket.id,
                message: messageInput,
                attach: listOfFiles,
                mentions: listOfMentions,
                type: props.typeNewMessage,
            }
        })
        .then(function (response) {
            setListOfFiles([]);
            setMessageInput('');
            setUploadedFiles([]);
            // document.getElementById("new_message_input").value = '';
            props.getMessages(props.ticket.id);
            props.setNewMessageOpen(!props.newMessageOpen);
            notify('Mensaje enviado', 'success');
            //props.getTicket();
        })
        .catch(function (err) {
            if (err.response.status === 400) {
                notify('Debes escribir un mensaje', 'warn');
            } else {
                notify('Algo no funcionó', 'error');
            }
        });
    }
    
    async function getProfiles(query, callback){
        await axios({
            method: 'get',
            url: API_ADDRESS + 'profiles/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                find_profile: true,
                query: query
            }
        })
        .then((res) =>
            res.data.map((profile) => ({display: profile.name + " " + profile.lastname, id: profile.user}))
        )
        .then(callback)        
    }
    
    // var ctrlKey = 17
    const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

    React.useEffect(() => {
        // var ctrlDown = false,
        // cmdKey = 91,
        // vKey = 86
        // document.getElementById('message-input').addEventListener('keydown', function(e) {
        //     if (e.keyCode == ctrlKey || e.keyCode == cmdKey) ctrlDown = true;
        // })
        // document.getElementById('message-input').addEventListener('keyup', function(e) {
        //     if (e.keyCode == ctrlKey || e.keyCode == cmdKey) ctrlDown = false;
        // })
        // document.getElementById('message-input').addEventListener('keydown', function(e) {
        //     if (ctrlDown && e.keyCode == vKey){
        //         console.log(navigator.clipboard.read())
        //     }
        // })
        // document.getElementById('message-input').addEventListener('keydown', async function(e) {
        //     if (ctrlDown && e.keyCode == vKey){
        //         // console.log(navigator.clipboard.read())
        //         const data = await navigator.clipboard.read()
        //         // console.log(data)
        //         for (const item of data) {
        //             for (const type of item.types) {
        //                 if (type === 'image/png'){
        //                     var blob = await item.getType('image/png');
        //                     var file = new File([blob], genRanHex(12)+ ".png", {type: 'image/png'})
        //                     postTicketAttach(file, genRanHex(12)+ ".png")
        //                     setUploadedFiles(uploadedFiles.concat(file))
        //                 }
        //             }
        //         }
        //     }
        // })
        document.getElementById('message-input').addEventListener('paste', async function(e) {
                const data = await navigator.clipboard.read()
                for (const item of data) {
                    for (const type of item.types) {
                        if (type === 'image/png'){
                            var blob = await item.getType('image/png');
                            var file = new File([blob], genRanHex(12)+ ".png", {type: 'image/png'})
                            postTicketAttach(file, genRanHex(12)+ ".png")
                            setUploadedFiles(current => [...current, file])
                        }
                    }
                }
            
        })
    }, [])

    return(
        <div 
            className={props.newMessageOpen ? 'box NewMessage open' : 'box NewMessage'}
            style={
                props.typeNewMessage && props.typeNewMessage === 'message' ? {backgroundColor: '#FFFFFF'} :
                props.typeNewMessage === 'internal' ? {backgroundColor: '#fffbeb'} :
                props.typeNewMessage === 'authorization' ? {backgroundColor: '#effaf3'} : {backgroundColor: '#FFFFFF'} 
            }
            id="NewMessageBox"
        >
            {/* backgroundColor: '#ffeea8',  */}
            <span style={{fontSize: '14px'}}>
                {props.typeNewMessage && props.typeNewMessage === 'message' && 'Nueva respuesta al ticket'}
                {props.typeNewMessage && props.typeNewMessage === 'internal' && 'Nota Interna'}
                {props.typeNewMessage && props.typeNewMessage === 'authorization' && 'Requerir autorización'}
            </span>
            <div className="close_new_message" onClick={() => props.setNewMessageOpen(!props.newMessageOpen)}>
                Cerrar
            </div>
            <MentionsInput
                //className="textarea"
                value={messageInput}
                onChange={e => {setMessageInput(e.target.value)}}
                style={mentionsInputStyle}
                placeholder="Escribe el mensaje aquí"
                allowSpaceInQuery={true}
                forceSuggestionsAboveCursor={true}
                id="message-input"
                // onKeyDown={(e) => handleKeyDown(e)}
            >
                <Mention 
                style={mentionStyles}
                data={getProfiles}
                markup="@[__display__]"
                trigger="@"
                onAdd={(id, display) => setListOfMentions(current => [...current, parseInt(id)])}
               
                />
            </MentionsInput>
          
            <div className="columns">
                <div className="column is-narrow">
                    <div {...getRootProps()}
                        className="upload_attach"
                    >
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Agrega archivos adjuntos aquí</p>
                        }
                    </div>
                    <ul style={{marginTop: '8px'}}>
                        {uploadedFiles.map((file, index) => (
                            <React.Fragment  key={index}>
                                <li className="attach_link_new">{file.name}</li>
                                <br />
                            </React.Fragment>
                        ))}
                    </ul>    
                </div>
                <div className="column" style={{float: 'right'}}>
                    <button className={isUploadingFile ? "button is-info is-small is-loading is-disabled" : "button is-info is-small"} onClick={() => postMessage()} style={{float: 'right'}}>
                        <span className="icon is-small">
                            <IonIcon icon={send} />
                        </span>
                        <span>Enviar mensaje</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export { NewMessage };