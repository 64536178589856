// React
import React from "react";
import axios from "axios";

// Context
import { AppContext } from "../../../../layout/AppContext";

// Icons
import { IonIcon } from "@ionic/react";
import { alertCircleOutline } from 'ionicons/icons';

function NoOwner(props) {

    const [loading, setLoading ] = React.useState(false);
    const [tcounter, setTCounter] = React.useState(0);

    const {API_ADDRESS, getToken} = React.useContext(AppContext);

    async function getTickets(justcount){
        setLoading(true)
        if (justcount == false){
            props.saveListWhat('noowner');
        }        
        await axios({
            method: 'get',
            url: API_ADDRESS + 'tickets/',
            headers: { Authorization: 'Token ' + getToken()},
            params: {
                noowner: true
            }
        })
        .then(function (response) {
            if (justcount == false){
                props.setTickets(response.data);
            }
            setTCounter(response.data.length);
            setLoading(false);
        })
        .catch(function (err) {
            setLoading(false);
        });
    }
    
    React.useEffect(() => {
        getTickets(true)
    }, [])  

    return(
        <div id={'noowner'} className="btn-tickets" onClick={() => getTickets(false) }>
            <IonIcon icon={alertCircleOutline} />
            <span> 
                {tcounter > 0 && <b> ({tcounter}) </b>}
                &nbsp; Sin Asignar
            </span>
        </div>
    );
}

export { NoOwner };