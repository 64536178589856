// React
import React from "react";
import { AppContext } from "../../../../layout/AppContext";

function TicketDetails(props){
    const {
        momentDateNormal
    } = React.useContext(AppContext)
    return(
        <div className="box" 
        style={{
            border: '2px solid #5a6672',
            borderRadius: '8px',
            marginBottom: '8px',
            padding: '12px 12px 5px 12px'
        }}
        >
            <h6 className="title is-6 has-text-centered" style={{marginBottom: '8px'}}>Detalles del ticket</h6>
            <p>
                Código:&nbsp;
                <strong>
                    {props.ticket.code}
                </strong>
            </p>
            <p>
                ID:&nbsp;
                <strong>
                    {props.ticket.id}
                </strong>
            </p>
            <p style={{borderTop: '1px solid #eee', marginTop: '4px'}}>
                Creado:&nbsp;
                <br />
                <strong>
                    {momentDateNormal(props.ticket.created_at)}
                </strong>
            </p>
            <p style={{borderTop: '1px solid #eee', marginTop: '4px'}}>
                Vencimiento
                <br />
                <strong>
                    {props.ticket.expiration ?
                    momentDateNormal(props.ticket.expiration)
                    : '-'
                    }
                </strong>
            </p>
            <p style={{borderTop: '1px solid #eee', marginTop: '4px'}}>
                Primera Respuesta
                <br />
                <strong>
                    {/* {props.ticket.first_answer_at} */}
                    {props.ticket.first_answer_maked ?
                    momentDateNormal(props.ticket.first_answer_at)
                    : '-'
                    }
                </strong>
            </p>
            <p style={{borderTop: '1px solid #eee', marginTop: '4px'}}>
                Última actualización:
                <br />
                <strong>
                    {props.ticket.last_update ?
                    momentDateNormal(props.ticket.last_update)
                    : '-'
                    }
                </strong>
            </p>
            <p style={{borderTop: '1px solid #eee', marginTop: '4px'}}>
                Ult. Mensaje
                <br />
                <strong>
                    {props.ticket.last_answer_at ?
                    momentDateNormal(props.ticket.last_answer_at)
                    : '-'
                    }
                </strong>
            </p>
            {/* <div className="columns is-mobile">
                <div className="column is-6">
                    Vencimiento
                </div>
                <div className="column is-6">
                    
                </div>
            </div> */}
        </div>             
    )
}

export { TicketDetails };